import { Box, Button, Chip, Divider, Fade, IconButton, Input, Modal, Tooltip, Typography, useTheme } from "@mui/material"
import WebListItem from "../Models/WebListItem"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useContext, useRef, useState } from "react";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import styled, { createGlobalStyle, css } from "styled-components";
import InfoIcon from '@mui/icons-material/Info';
import useMediaQuery from "../Hooks/useMediaQuery";
import { WeblistTag } from "../Models/WeblistTag";
import ApiService from "../Services/ApiService";
import UserContext from "../Contexts/UserContext";
import { MessagService } from "../Services/MessageService";

interface IMobileMenu {
    handleSaveEditTouch: (state: number) => void;
    togglePinnedMobile: (item: WebListItem) => void;
    items: WebListItem[];
    show: boolean;
    longpressItem: WebListItem | null;
    setLongPressItem: React.Dispatch<React.SetStateAction<WebListItem | null>>;
    selectedEdit: number;
    setSelectedEdit: React.Dispatch<React.SetStateAction<number>>;
    deleteItem: WebListItem | null;
    setDeleteItem: React.Dispatch<React.SetStateAction<WebListItem | null>>;
}

interface ICreateCategory {
    createCategoryModal: boolean;
    categoryColor: string;
    setCategoryColor: React.Dispatch<React.SetStateAction<string>>;
    setCreateCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleCreateCategory: () => void;
    closeModal: () => void
}

interface ICreateTag {
    createTagModal: boolean;
    setCreateTagModal: React.Dispatch<React.SetStateAction<boolean>>;
    tags: Array<WeblistTag>
}

const CreateCategory = (props: ICreateCategory) => {

    const isDesktop = useMediaQuery('(min-width: 1400px)');

    const theme = useTheme()

    const [categories, setCategories] = useState<string[]>([])
    const [currentCategory, setCurrentCategory] = useState<string>("")
    const categoryInput = useRef<HTMLInputElement>();

    const createCategoryStyle = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 6,
        paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'center',
        padding: '20px',
        minWidth: isDesktop ? '600px' : '90vw',
        minHeight: '400px'
    };



    const CategoryStyle = createGlobalStyle`
        .weblist-column-container{

        
            display: flex;
            overflow: hidden;
            transition: 0.2s all;
            align-items: center;
            padding: 6px 12px;
            margin: 12px 0;
            box-shadow: rgb(99 99 99 / 12%) 1px 2px 2px 1px;
            ${theme.palette.mode === 'dark' && css`
                color: white;
                background-color: ${theme.palette.secondary.dark};
            `}

            p{
                flex:1;
            }
            button{
               opacity: 0;
            }

            &:hover{
                button{
                    opacity: 1;
            }
            }
        }
    `

    const addCategory = () => {

        if (categories.some(x => x === currentCategory)) {
            MessagService.showSnackbar("Et stadie med det valgte navn er allerede indtastet", true)
            return;
        }

        if (currentCategory.toLocaleLowerCase() === "mine noter") {
            MessagService.showSnackbar("Du kan ikke bruge navnet 'Mine noter', da det er navnet på standard stadiet", true)
            return;
        }

        if (currentCategory.length === 0) {
            MessagService.showSnackbar("Indtast et navn på stadiet", true)
            return;
        }

        let count = categories.length;

        if (count >= 5) {
            MessagService.showSnackbar("Du kan max vælge 5 stadier til en liste", true)
            return;
        }

        setCategories([...categories, currentCategory])
        setCurrentCategory("")
        categoryInput.current?.focus()
    }
    return (
        <Modal
            open={props.createCategoryModal}
            onClose={() => { props.setCreateCategoryModal(false) }}
            aria-labelledby="create-category-modal"
            aria-describedby="create-category-modal"
        >
            <>
                <CategoryStyle />

                <Box sx={createCategoryStyle}>
                    <Typography sx={{ color: theme.palette.mode === 'dark' ? 'whitesmoke' : '' }} variant="h6" component="h2">
                        <PlaylistAddIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} />
                        Opret liste
                    </Typography>

                    <Typography textAlign={"left"} sx={{ mt: 2 }}>
                        <Input fullWidth={true} id="createCategoryText" placeholder="Navn" sx={{ margin: '0 5px 0 5px' }}></Input>

                    </Typography>
                    <Typography marginTop={5} textAlign={"left"}>
                        <label style={{ color: theme.palette.mode === 'dark' ? 'whitesmoke' : '' }}>Vælg farve</label>
                        <input value={props.categoryColor} onChange={(e) => { props.setCategoryColor(e.target.value) }} type={'color'} id="categoryColor" style={{ margin: '0 5px 0 5px' }}></input>
                        <Tooltip sx={{ color: theme.palette.mode === 'dark' ? 'white' : '' }} title="Giv din liste en farve for nemmere at kunne skelne den fra de andre lister">
                            <InfoIcon fontSize="small" />
                        </Tooltip>
                    </Typography>
                    <Typography display={"flex"} marginTop={5} textAlign={"left"}>
                        <Input onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                addCategory()
                            }
                        }} ref={categoryInput} value={currentCategory} fullWidth={true} placeholder="Tilføj kolonne (max 5 i alt)" sx={{ flex: '1' }} onChange={(e) => {
                            setCurrentCategory(e.target.value)
                        }}></Input>
                        <Button onClick={(e) => {

                            addCategory()
                        }}>gem</Button>
                    </Typography>
                    <div style={{ flex: '1', margin: '12px 0' }}>
                        {categories.map(item => {
                            return <Fade in={true} key={"cat_" + item.toString()}>
                                <div className="weblist-column-container">
                                    <Typography className="weblist-column-title" style={{ textAlign: 'left' }}>{item}</Typography>
                                    <IconButton onClick={() => {
                                        setCategories([...categories.filter(x => x !== item)])
                                    }}>
                                        <DeleteIcon></DeleteIcon>
                                    </IconButton>

                                </div>
                            </Fade>
                        })}
                    </div>
                    <Typography textAlign={"left"}>
                        <Button variant="contained" onClick={() => {

                            (document.getElementById("categoryColor") as HTMLInputElement).value = "#FFFFFF"
                            setCategories([])
                            props.handleCreateCategory()

                        }}>Opret</Button>
                        <Button variant="outlined" style={{ marginLeft: '10px' }} onClick={() => {
                            props.closeModal()
                        }}>Luk</Button>
                    </Typography>
                </Box>
            </>
        </Modal>
    )

}

const MobileMenu = (props: IMobileMenu) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 6,
        minWidth: '90%',
        paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'center'
    };

    return (
        <Modal
            open={props.longpressItem !== null}
            onClose={() => { props.setLongPressItem(null) }}
        >

            <Box sx={style}>
                <div>
                    <Typography variant="subtitle2" component="h1">
                        Vælg stadie
                    </Typography>
                    <Button disabled={props.longpressItem?.state === 1} onClick={() => { props.handleSaveEditTouch(1) }}>Planlagt</Button>
                    <Button disabled={props.longpressItem?.state === 2} onClick={() => { props.handleSaveEditTouch(2) }}>Aktiv</Button>
                    <Button disabled={props.longpressItem?.state === 3} onClick={() => { props.handleSaveEditTouch(3) }}>Udført</Button>
                </div>
                <Divider sx={{ marginLeft: '10px', marginRight: '10px' }}></Divider>
                <div>
                    {props.longpressItem !== null &&
                        <div>

                            <IconButton sx={{ margin: '10px' }} size="medium" onClick={() => {
                                if (props.selectedEdit === 0 && props.longpressItem) {
                                    props.setSelectedEdit(props.longpressItem.id)
                                    setTimeout(() => {
                                        let el = (document.getElementById(`edit-${props.longpressItem?.id || 0}`) as HTMLInputElement)
                                        if (el) {
                                            el.value = props.longpressItem?.title || ""
                                        }
                                    }, 50)
                                }
                                else {
                                    props.setSelectedEdit(0)
                                    let el = (document.getElementById(`edit-${props.longpressItem?.id || 0}`) as HTMLInputElement)
                                    if (el) {
                                        el.value = ""
                                    }
                                }

                                props.setLongPressItem(null)
                            }}>
                                {props.selectedEdit === 0 && <EditIcon fontSize="small"></EditIcon>}

                            </IconButton>
                            <IconButton sx={{ margin: '10px' }} size="medium" onClick={() => {
                                let target = props.items.find(item => item.id == props.longpressItem?.id || 0);
                                if (target) {
                                    props.setDeleteItem(target)
                                }

                                props.setLongPressItem(null)
                            }}>
                                <DeleteIcon fontSize="small"></DeleteIcon>
                            </IconButton>
                            <IconButton sx={{ margin: '10px' }} size="medium" onClick={() => {

                                props.togglePinnedMobile(props.longpressItem || new WebListItem())
                                props.setLongPressItem(null)
                            }}>
                                <PushPinIcon fontSize="small"></PushPinIcon>
                            </IconButton>
                        </div>
                    }
                </div>

            </Box>
        </Modal>)

}

const StyledBox = styled(Box) <{ darkTheme: boolean }>`
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background-color: ${props => props.darkTheme ? '' : 'whitesmoke'};
    box-shadow: 6px;
    min-width: 40vw;
    padding: 10px; 
    min-height: 40vh;
    display: flex;
    flex-direction: column;

    >section{
    
        background-color: ${props => props.darkTheme ? '' : 'whitesmoke'};
        height: 100%;
    }

    .create-tag{
        padding:10px;
        min-height: 100px;
        input{
            min-width: 300px;
        }
    }
   
    .created-tags{
        background-color: ${props => props.darkTheme ? '' : 'whitesmoke'};
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        align-content: flex-start;
        flex-direction: row;
    }

`

const CreateTag = ({ createTagModal, setCreateTagModal, tags }: ICreateTag) => {

    const userContext = useContext(UserContext)
    const theme = useTheme()

    const [createTagTitle, setCreateTagTitle] = useState("")

    return (
        <Modal
            open={createTagModal}
            onClose={() => { setCreateTagModal(false) }}
        >
            <StyledBox darkTheme={theme.palette.mode === 'dark'}>
                <section className="create-tag">

                    <div>
                        <Input value={createTagTitle} onChange={(e) => { setCreateTagTitle(e.target.value) }} placeholder="Tag navn"></Input>
                        <Button disabled={createTagTitle.length === 0} onClick={async () => {
                            let result = await ApiService.postWeblistTag(userContext!.passwordHash, createTagTitle)
                            if (result.isSuccessful) {
                                let event = new CustomEvent('tagsUpdated', { detail: JSON.stringify(result.data) })
                                window.dispatchEvent(event)
                            }
                            else {

                            }
                        }}>Gem tag</Button>
                    </div>
                </section>
                <Divider>
                    <Typography sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>Oprettede tags</Typography>
                </Divider>
                <section className="created-tags">
                    {tags?.map((tag) => {
                        return <div>
                            <Chip sx={{ margin: '5px' }} onDelete={async () => {

                                let result = await ApiService.deleteWeblistTag(userContext!.passwordHash, tag.id)
                                if (result.isSuccessful) {
                                    let event = new CustomEvent('tagsUpdated', { detail: JSON.stringify(tags.filter(t => t.id !== tag.id)) })
                                    window.dispatchEvent(event)
                                }

                            }} label={tag.title}></Chip>
                        </div>
                    })}
                </section>

            </StyledBox>
        </Modal>)
}

export { MobileMenu, CreateCategory, CreateTag };