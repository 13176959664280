import styled, { createGlobalStyle, css } from "styled-components";
import ListIcon from '@mui/icons-material/List';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from "../Hooks/useMediaQuery";
import React, { useContext, useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ArchiveIcon from '@mui/icons-material/Archive';
import InfoIcon from '@mui/icons-material/Info';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import UserContext from "../Contexts/UserContext";
interface INavbar {
    toggleTheme: () => void
}

function Navbar({ toggleTheme }: INavbar) {

    const protectedPaths = ["/", "/arkiv", "/listedeling"]

    const userContext = useContext(UserContext)
    const navigate = useNavigate()
    const theme = useTheme();

    const location = useLocation()

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const GlobalStyle = createGlobalStyle`
      a.active{
          font-weight:bold;          
      }
`

    const LinkWrapper = styled(NavLink)`
        
        text-decoration: none;
        margin: 6px;
        color:${props => theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main};
        transition: 0.2s all;
        padding: 5px;
        border-radius: 10px;
        height: fit-content;
        min-height: 25px;
        display: inline-block;
        font-weight: 300;
        font-size: 18px;

        >span{
            vertical-align: super;
            margin-left: 5px;
        }
    `

    const WeblistLogoWrapper = styled.span`
        
        img{
            vertical-align: bottom; 
            cursor: pointer;
            margin-right: 20px;
            background-color: white;
            opacity: 0.9;
            ${theme.palette.mode === 'dark' && css`
            clip-path: circle(38%)
            ` }
           ;
        }
    `

    useEffect(() => {
        window.addEventListener('userFound', () => {
            navigate("oversigt")
        })
    }, [])

    return (
        <div className="app-navbar">

            <GlobalStyle></GlobalStyle>


            {isDesktop &&
                <>
                    <WeblistLogoWrapper>
                        <img onClick={() => {
                            navigate("/")
                        }} className='weblist-logo' src={require('../Images/icons/apple-icon-60x60.png')} width='50'></img>
                    </WeblistLogoWrapper>
                    {userContext && <>
                        <LinkWrapper
                            tabIndex={-1}
                            to={`/oversigt`}
                            key={1}
                            className="navbar-link"
                        >

                            <span >Oversigt</span>
                        </LinkWrapper>
                        <LinkWrapper
                            tabIndex={-1}
                            hidden={!userContext}
                            to={`/arkiv`}
                            key={2}
                            className="navbar-link"
                        >

                            <span>Arkiv</span>
                        </LinkWrapper>
                        <LinkWrapper
                            tabIndex={-1}
                            hidden={!userContext}
                            to={`/listedeling`}
                            key={3}
                            className="navbar-link"
                        >

                            <span>Listedeling</span>
                        </LinkWrapper>
                    </>}
                    
                    <LinkWrapper
                        tabIndex={-1}
                        to={`/om`}
                        key={4}
                        className="navbar-link"
                    >

                        <span>Om</span>
                    </LinkWrapper>



                </>
            }

            {(!isDesktop && userContext) &&
                <>
                    <div>
                        <IconButton

                            id="hamburgerMenuButton"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            style={{ marginTop: '8px' }}
                            onClick={handleClick}
                        >
                            <MenuIcon></MenuIcon>
                        </IconButton>
                        <Menu
                            id="HamburgerMenu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <LinkWrapper
                                    to={`/oversigt`}
                                    key={1}
                                >

                                    <span>Oversigt</span>
                                </LinkWrapper>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <LinkWrapper
                                    to={`/bruger`}
                                    key={2}
                                >

                                    <span>Bruger</span>
                                </LinkWrapper>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <LinkWrapper
                                    to={`/arkiv`}
                                    key={3}
                                >

                                    <span>Arkiv</span>
                                </LinkWrapper>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <LinkWrapper
                                    to={`/om`}
                                    key={4}
                                >

                                    <span>Om</span>
                                </LinkWrapper>
                            </MenuItem>
                          
                            <MenuItem>
                                <span style={{ verticalAlign: 'super', marginLeft: '-5px', color: theme.palette.mode === 'dark' ? '#fff' : '' }} title="farvetema">
                                    <IconButton onClick={toggleTheme} sx={{ ml: 1 }} >
                                        {theme.palette.mode === 'dark' ? <>
                                            <Brightness7Icon />
                                            <Typography marginLeft={'5px'}>Lyst tema</Typography>
                                        </> :
                                            <>
                                                <Brightness4Icon />
                                                <Typography marginLeft={'5px'}>Mørkt tema</Typography>
                                            </>
                                        }

                                    </IconButton>
                                </span>
                            </MenuItem>
                        </Menu>
                    </div>
                </>}

        </div >)
}

export default Navbar;