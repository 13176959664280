import { BaseDtoModel } from "./BaseDtoModel";

export class EditWeblistModel extends BaseDtoModel {
    title: string = "";
    details: string = "";
    state: number = 0;
    weblistId: number = 0;
    pinned?: boolean = false;
    weblistCategoryId?: number = 0;
    restoreAllRelatedWeblists?: boolean = false;
    tags: string = "";
    deadline: string = "";
}