import moment from "moment"

const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const urlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)

const getDeadlineDate = (deadline: string): Date => {
    let arr = deadline.split('.')
    if (arr.length === 5) {

        let deadlineDate = new Date(+arr[2], +arr[1] - 1, +arr[0], +arr[3], +arr[4])

        return deadlineDate
    }
    return new Date()
};

const getTimeToDeadline = (deadline: string): string => {

    let arr = deadline.split('.')
    if (arr.length === 5) {

        let now = new Date()
        let deadlineDate = getDeadlineDate(deadline)


        var a = moment([now.getFullYear(), now.getMonth(), now.getDate()]);
        var b = moment([deadlineDate.getFullYear(), deadlineDate.getMonth(), deadlineDate.getDate()]);
        let diff = b.diff(a, 'days')

        if (diff < 0) return diff === -1 ? 'Over 1 dag' : `Over ${Math.abs(diff)} dage`
        if (diff === 0) return `i dag`
        return `${diff} dage`
    }

    return deadline

}

const getFormattedDeadline = (deadline: string): string => {
    let date = getDeadlineDate(deadline)
    return date.toLocaleString("da-DK")
}

const formatDateTime = (date: Date, includeHour?: boolean): string => {

    let d = new Date(date)
    let hour = d.toLocaleString('da-DK', { hour: 'numeric', minute: 'numeric' })
    if (includeHour) {
        return `${d.toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${hour}`
    }
    return `${d.toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
}



const validEmail = (email: string): boolean => {
    return emailRegex.test(email)
}

const validUrl = (url: string): boolean => {
    return urlRegex.test(url)
}


export { getDeadlineDate, getTimeToDeadline, getFormattedDeadline, formatDateTime, validEmail, validUrl }
