import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import styled, { css } from "styled-components";
import UserContext from "../Contexts/UserContext";
import useMediaQuery from "../Hooks/useMediaQuery";
import IComponent from "../Interfaces/IComponent";
import { UserModel } from "../Models/UserModel";
import ApiService from "../Services/ApiService";
import { NavLink } from "react-router-dom";
import { validEmail } from "../Services/UtilsService";
import Carousel from "better-react-carousel";
import CheckIcon from "@mui/icons-material/Check";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DevicesIcon from "@mui/icons-material/Devices";
import { MessagService } from "../Services/MessageService";
import { GoogleLogin } from "@react-oauth/google";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SettingsIcon from '@mui/icons-material/Settings';

interface ILogin extends IComponent {
  loginComplete: (e: any) => void;
  clearUser: () => void;
  toggleTheme: () => void;
}

interface IUserWrapper {
  isMobile: boolean;
}

interface IValidation {
  isDirty: boolean;
  error: string;
}

const UserWrapper = styled.div<IUserWrapper>`
  place-self: center center;
  position: absolute;
  height: 600px;
  top: 10vh;

  ${(props) =>
    !props.isMobile &&
    css`
      max-width: 50vw;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      margin: initial;
      display: block;
      min-width: 80vw;
      margin: 10px;
    `}

    input {
    /* max-width: 800px;
        min-width: 800px; */

    ${(props) =>
      props.isMobile &&
      css`
        max-width: auto;
        min-width: auto;
      `}
  }

  .icon-text {
    vertical-align: text-bottom;
    margin-left: 5px;
  }

  .create-user-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    place-self: stretch self-start;
    margin: 10px 0 10px 0;
  }

  .weblist-logo {
    background-color: whitesmoke;
    clip-path: circle(40%);
  }

  .google-button {
    vertical-align: bottom;
    margin-left: 10px;
    margin-top: 15px;
  }
`;

function Login(props: ILogin) {
  const theme = useTheme();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const user = useContext(UserContext);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [emailError, setEmailError] = useState<IValidation>({
    error: "",
    isDirty: false,
  });
  const [passwordError, setPasswordError] = useState<IValidation>({
    error: "",
    isDirty: false,
  });

  const [loginError, setLoginError] = useState("")

  const handleKeyUp = (e: any) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (
      emailError.error.length > 0 ||
      passwordError.error.length > 0 ||
      email.length === 0 ||
      password.length === 0
    ) {
      return;
    }

    setLoginError("")
    MessagService.showLoader("Logger ind...")

    let result = await ApiService.login(email, password);

    if (result.isSuccessful) {
      let user = result.data as UserModel;

      props.loginComplete(user);

      let event = new CustomEvent("userFound");
      window.dispatchEvent(event);
    } else {
      console.log(result.error);
      let error = (result as any).error.response.data;
      setLoginError(error)
      MessagService.stopLoader()
    }
  };

  const handleGoogleSuccess = async (jwt: any) => {
    let parsed = parseJwt(jwt.credential);

    if (parsed) {
      let result = await ApiService.loginOrCreateUser(
        parsed.name,
        parsed.email,
        jwt.credential
      );

      if (result.isSuccessful) {
        let user = result.data as UserModel;

        props.loginComplete(user);

        let event = new CustomEvent("userFound");
        window.dispatchEvent(event);
      }
    }
  };

  const parseJwt = (token: string) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  return (
    <UserWrapper isMobile={!isDesktop}>
      {user ? (
        <Box minWidth={isDesktop ? "30vw" : "90vw"}>
          <Card>
            <CardContent>
              <h5>Logget ind</h5>
              <div>{user.name}</div>
              <div>{user.email}</div>

              <Button
                sx={{ marginTop: "10px" }}
                variant="contained"
                onClick={() => {
                  props.clearUser();
                }}
              >
                Log ud
              </Button>

              <section>
                <Typography fontSize={'16px'} fontWeight='bold' mt={'30px'}>Indstillinger</Typography>
               
                <div
                  style={{
                    verticalAlign: "super",
                    marginLeft: "-5px",
                    color: theme.palette.mode === "dark" ? "#fff" : "",
                  }}
                  title="farvetema"
                >
                  <span style={{marginLeft:'5px'}}>{theme.palette.mode === "dark" ?  'mørkt tema' : 'lyst tema'}</span>
                  <IconButton onClick={props.toggleTheme} sx={{ ml: 1 }}>
                    {theme.palette.mode === "dark" ? (
                      <>
                        <Brightness7Icon />
                      </>
                    ) : (
                      <>                  
                        <Brightness4Icon />
                      </>
                    )}
                  </IconButton>
                </div>
              </section>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <>
          <Box p={"20px"} maxWidth={"100vw"}>
            <Typography fontSize={20}>
              <img
                className="weblist-logo"
                style={{ verticalAlign: "middle" }}
                src={require("../Images/icons/apple-icon-60x60.png")}
                width="50"
              ></img>
              <span style={{ verticalAlign: "middle" }} className="icon-text">
                <Typography
                  display={"inline-block"}
                  fontSize="24px"
                  className="light-text"
                >
                  Weblist.dk
                </Typography>
              </span>
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <TextField             
                onKeyUp={handleKeyUp}
                error={emailError.error.length > 0 || loginError.length > 0}
                fullWidth={true}
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError({
                    error: validEmail(e.target.value) ? "" : "Ugyldig email",
                    isDirty: true,
                  });
                }}
              ></TextField>
              {emailError.error.length > 0 && emailError.isDirty && (
                <div>
                  <Typography color={"indianred"}>
                    {emailError.error}
                  </Typography>
                </div>
              )}
            </div>
            <div style={{ marginTop: "10px" }}>
              <TextField
                error={loginError.length > 0}
                onKeyUp={handleKeyUp}
                fullWidth={true}
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError({
                    error: e.target.value.length < 5 ? "Indtast password" : "",
                    isDirty: true,
                  });
                }}
              ></TextField>
              {passwordError.error.length > 0 && passwordError.isDirty && (
                <div>
                  <Typography color={"indianred"}>
                    {passwordError.error}
                  </Typography>
                </div>
              )}
            </div>
            
            {loginError.length > 0 && 
            <Box sx={{color:'#d0021b', display:'flex'}}>
              {/* <PriorityHighIcon/> */}
              <Typography  mt={'10px'}>{loginError}</Typography>
            </Box>
            }
            <Button
              sx={{ marginBottom: "15px" }}
              disabled={
                emailError.error.length > 0 ||
                passwordError.error.length > 0 ||
                email.length === 0 ||
                password.length === 0
              }
              style={{ marginTop: "10px" }}
              variant="contained"
              onClick={handleLogin}
            >
              Log ind
            </Button>

            <GoogleLogin
              text="signin_with"
              onSuccess={(credentialResponse: any) => {
                handleGoogleSuccess(credentialResponse);
              }}
              onError={() => {
                MessagService.showSnackbar(
                  "Google login fejlede, prøv igen om lidt eller genindlæs side.",
                  true
                );
              }}
            />
            <div className="create-user-link">
              <Typography fontSize={14} marginTop="10px" className="light-text">
                <NavLink
                  style={{
                    color: theme.palette.mode === "dark" ? "white" : "",
                  }}
                  to={"/opret"}
                >
                  Klik her{" "}
                </NavLink>
                for at oprette ny bruger
              </Typography>
              <Typography fontSize={14} marginTop="10px" className="light-text">
                <NavLink
                  style={{
                    color: theme.palette.mode === "dark" ? "white" : "",
                  }}
                  to={"/nulstil"}
                >
                  Klik her
                </NavLink>{" "}
                hvis du har glemt dit password
              </Typography>
            </div>
            {/* https://github.com/DevPanther/better-react-carousel */}
          </Box>

          <Box mt={"100px"} style={{ maxWidth: isDesktop ? "60vw" : "90vw" }}>
            <Divider
              sx={{
                fontWeight: "bold",
                marginTop: "100px",
                fontSize: "18px",
                color: theme.palette.mode === "dark" ? "white" : "",
              }}
            >
              Hvad er Weblist.dk?
            </Divider>

            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: theme.palette.mode === "dark" ? "white" : "" }}
                  primary="Gratis og nemt"
                  secondary="Det er både gratis og nemt at bruge Weblist.dk, og helt uden reklamer."
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FormatListBulletedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: theme.palette.mode === "dark" ? "white" : "" }}
                  primary="Opret lister"
                  secondary="Opret dine egne, tilpassede to-do lister som matcher dine behov"
                />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PeopleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: theme.palette.mode === "dark" ? "white" : "" }}
                  primary="Del dine lister"
                  secondary="Det er muligt at dele dine lister med andre brugere, så flere kan læse og skrive noter på samme liste"
                />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <NotificationsActiveIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: theme.palette.mode === "dark" ? "white" : "" }}
                  primary="Få påmindelser"
                  secondary="Opret noter med deadline og få en mail dagen inden"
                />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <DevicesIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: theme.palette.mode === "dark" ? "white" : "" }}
                  primary="Virker på alle enheder"
                  secondary="Weblist.dk er tilpasset både desktop og mobil og kan installeres som app for endnu hurtigere adgang"
                />
              </ListItem>
            </List>
          </Box>

          {isDesktop && (
            <Box
              mt={"100px"}
              pb={"100px"}
              style={{ maxWidth: isDesktop ? "60vw" : "100vw" }}
            >
              <Divider
                sx={{
                  fontWeight: "bold",
                  marginBottom: "100px",
                  fontSize: "18px",
                  color: theme.palette.mode === "dark" ? "white" : "",
                }}
              >
                Få et smugkig
              </Divider>
              <Carousel width={"1"} cols={1} rows={1} gap={10} loop>
                <Carousel.Item>
                  <img
                    style={{
                      maxWidth: isDesktop ? "55vw" : "95vw",
                      borderRadius: "10px",
                    }}
                    loading="lazy"
                    src={require("../Images/Screenshots/screenshot1.png")}
                  ></img>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    style={{
                      maxWidth: isDesktop ? "55vw" : "95vw",
                      borderRadius: "10px",
                    }}
                    loading="lazy"
                    src={require("../Images/Screenshots/screenshot2.png")}
                  ></img>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    style={{
                      maxWidth: isDesktop ? "55vw" : "95vw",
                      borderRadius: "10px",
                    }}
                    loading="lazy"
                    src={require("../Images/Screenshots/screenshot3.png")}
                  ></img>
                </Carousel.Item>
              </Carousel>
            </Box>
          )}
        </>
      )}
    </UserWrapper>
  );
}

export default Login;
