import { FormGroup, FormControlLabel, Checkbox, IconButton, Tooltip, useTheme } from "@mui/material";
import { useContext } from "react";
import UserContext from "../Contexts/UserContext";
import { Weblist } from "../Models/Weblist";
import WebListItem from "../Models/WebListItem";
import ApiService from "../Services/ApiService";
import { MessagService } from "../Services/MessageService";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import styled from "styled-components";

interface IMultiSelectComponent {
    multiSelectState: boolean;
    setMultiSelectState: (checked: boolean) => void;
    setMultiSelectValues: React.Dispatch<React.SetStateAction<number[]>>;
    multiSelectValues: Array<number>;
    selectedWeblist: Weblist | null;
    weblistItems: WebListItem[];
    setWeblistItems: React.Dispatch<React.SetStateAction<WebListItem[]>>

}

const MultiSelectWrapper = styled.div`
    display: flex;
    margin-left: 20px;
`

const MultiSelectComponent = ({ setMultiSelectValues, multiSelectState, setMultiSelectState, multiSelectValues, selectedWeblist, weblistItems, setWeblistItems }: IMultiSelectComponent) => {

    const userContext = useContext(UserContext)
    const theme = useTheme();

    function getLabel(): string {
        if (multiSelectValues.length > 0) {
            return `Vælg flere (${multiSelectValues.length} valgt)`
        }
        return "Vælg flere";
    }

    return (
        <MultiSelectWrapper>
            <FormGroup sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <FormControlLabel value={multiSelectState} onChange={(e, checked) => {
                    setMultiSelectState(checked)
                    if (!checked) {
                        setMultiSelectValues([]);
                    }
                }} control={<Checkbox />} label={getLabel()} />
            </FormGroup>
            {multiSelectValues.length > 0 &&
                <div>
                    <Tooltip title="Slet valgte noter">
                        <IconButton onClick={async () => {
                            let result = await ApiService.multiActions(userContext?.passwordHash!, multiSelectValues, "Delete", selectedWeblist?.id)
                            if (result.isSuccessful) {

                                let removed = result.data as WebListItem[];
                                let data = weblistItems.filter(item => !removed.some(x => x.id == item.id));
                                setWeblistItems(data)
                                setMultiSelectValues([]);
                            }
                            else {
                                MessagService.showSnackbar("Fejl under sletning af note(r). Prøv igen.", true)
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Arkivér valgte noter">
                        <IconButton onClick={async () => {
                            let result = await ApiService.multiActions(userContext?.passwordHash!, multiSelectValues, "Archive", selectedWeblist?.id)
                            if (result.isSuccessful) {

                                let removed = result.data as WebListItem[];
                                let data = weblistItems.filter(item => !removed.some(x => x.id == item.id));
                                setWeblistItems(data)
                            }
                            else {
                                MessagService.showSnackbar("Fejl under arkivering af note(r). Prøv igen.", true)
                            }
                        }}>
                            <ArchiveIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            }
        </MultiSelectWrapper>
    )
}

export default MultiSelectComponent;