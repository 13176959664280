import { Theme } from "@mui/material"
import styled, { css, useTheme } from "styled-components"
import { ICardWrapper } from "../Interfaces/ICardWrapper"
import { ICategoryColorCircle, ICategorySelector, IOverviewTopbar } from "../Interfaces/ICategorySelector"



export const CategoryColorCircle = styled.div<ICategoryColorCircle>`
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: ${props => props.color};
    display: inline-block;
    margin-right: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 8px 8px 0px;
`

export const CategorySelectorWrapper = styled.div<ICategorySelector>`
    position: absolute;
    top:10px;
    right:10px;
    min-width: 250px;
    display: flex;

    .categorySelect{
        min-width: 150px;

        ${props => !props.isDesktop && css`
            max-width: 150px;
        `}
    }
`

export const OverviewTopbar = styled.div<IOverviewTopbar>`

    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: ${props => props.isDesktop ? 'row' : 'column'};
    max-width: 400px;
    margin:0 auto;
    height: 0;

    >div:nth-child(2){
        margin-left: ${props => props.isDesktop ? '15px' : ''};
        margin-top: ${props => props.isDesktop ? '' : '15px'};
    }

    ${props => !props.isDesktop && css`
        height: auto;
    `}
  
    ${props => props.showDeadline && css`
        height: auto;
    `}

    .datepicker-section{
        flex-direction: row;
    }

${props => !props.isDesktop && css`
    padding:20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`}

>div:nth-child(1){

    >div{
        width: 100%;
    }
    flex:3;
}
>div:nth-child(2){
    flex:1;
}
>div:nth-child(3){
    flex:1;
}


button{
    margin-left: 10px;
}
`

export const DeadlineSection = styled.div<{ isDesktop: boolean }>`
    display: flex;
    justify-content: center;
    margin: 10px 20px;
`

export const CardWrapper = styled.div<ICardWrapper>` 

    .bounce-top {
        -webkit-animation: bounce-top 0.9s both;
                animation: bounce-top 0.9s both;
    }

    .slide-in-blurred-top {
	animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
    @keyframes slide-in-blurred-top {
    0% {
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 0%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
    }


    @-webkit-keyframes bounce-top {
        0% {
            -webkit-transform: translateY(-45px);
                    transform: translateY(-45px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
            opacity: 1;
        }
        24% {
            opacity: 1;
        }
        40% {
            -webkit-transform: translateY(-24px);
                    transform: translateY(-24px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        65% {
            -webkit-transform: translateY(-12px);
                    transform: translateY(-12px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        82% {
            -webkit-transform: translateY(-6px);
                    transform: translateY(-6px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        93% {
            -webkit-transform: translateY(-4px);
                    transform: translateY(-4px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87% {
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
        }
        100% {
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
            opacity: 1;
        }
        }
        @keyframes bounce-top {
        0% {
            -webkit-transform: translateY(-45px);
                    transform: translateY(-45px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
            opacity: 1;
        }
        24% {
            opacity: 1;
        }
        40% {
            -webkit-transform: translateY(-24px);
                    transform: translateY(-24px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        65% {
            -webkit-transform: translateY(-12px);
                    transform: translateY(-12px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        82% {
            -webkit-transform: translateY(-6px);
                    transform: translateY(-6px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        93% {
            -webkit-transform: translateY(-4px);
                    transform: translateY(-4px);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87% {
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
        }
        100% {
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
            opacity: 1;
        }
    }

    transition: 0.3s all;
    position: relative;

    >div{
        margin: 5px 10px 5px 10px;
        cursor: pointer;
        position: relative;
    }
   
    .multi-select{
        
        border:1px solid ${props => props.isDarkTheme ? 'white' : 'black'};
    }

    .note-list-item{
        
        box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 6px 0px;
        transition: 0.5s all;
        
        min-height: ${props => props.isDesktop ? '110px' : '100px'};
        background-color: ${props => props.theme.palette.mode === 'dark' ? props.theme.palette.secondary.dark : ''};
        /* background-color: red; */
        >div{
            background-color: ${props => props.color};
            ${props => !props.isDesktop && css`
               margin-bottom: -10px;

        
        `}
        }

        &:hover{
            
            .list-item-buttons{
                transform: translateY(0);
            }
        }

        .list-item-buttons{

            z-index: 50;
            right: 0;
            width: 100%;
            background-color: #f9f9f9; 
            transform: translateY(200px);
            transition: 0.4s all;
           
            >div{
      
                transition: 0.5s all;
                box-shadow: rgb(99 99 99 / 25%) 1px 1px 2px 1px;
                display: flex;
                justify-content: center;
                background-color: ${props => props.isDarkTheme ? props.theme.palette.secondary.dark : '#f6f9ff'}; 
                height:auto;

                button:disabled{
                    opacity: 0.3;
                }

                >span:nth-child(1){
                    align-self: center;
                    margin-left: 5px;
                    /* width: 100%; */
                }
                >span:nth-child(2){
                    align-self: center;
                    flex:1;
                    margin-left: 5px;
                    text-align: center;
                }
                >span:nth-child(3){
                                
                    /* margin-right: -15px; */
                    >button{
                        width: 26px;
                        height: 26px;
                        margin: 2px 3px;
                    }
                }
                >span:nth-child(4){
                    align-self: center;
                }
            }
        }
    }

     .category-circle{
        position: absolute;
        right: 5px;
        top: ${props => props.isDesktop ? '3px' : '5px'};
        z-index: 3;
    }
    `
export const ListItemButtons = styled.div`
    position:absolute;
    bottom: 0px;
    right: 25px;

`

export const BoxWrapper = styled.div<{ isDesktop: boolean }>`
    display: flex; 
    flex-direction: row; 
    ${props => !props.isDesktop && css`
    flex-direction: column; 
    `}
   
    justify-content: center;

`

export const ListContainerWrapper = styled.div<{ deadlineActive: boolean, showClearAllIcon: boolean, isDesktop: boolean, maxWidth?: number, theme: Theme }>`

    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px;
    ${props => !props.isDesktop && props.deadlineActive && css`
        margin-top: 80px;
    `}
    flex:1;
    ${props => props.isDesktop && css`       
    
`}
    border-radius: 3px;
    min-height: 85vh;
    cursor: pointer;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    ${props => !props.isDesktop && css`
    min-height: auto;
    `}

    ${props => props.isDesktop && css`
        max-width: 60vw;
    `}
   

    .clear-all{
        display: none;
        ${props => props.showClearAllIcon && css`        
            position: absolute;
            right: 10px;
            color:white !important;
            display: block;
        `}

    }

    .state-icon{
       color:white;
    }

.listcontainer-header{
    background-color: ${props => props.theme.palette.mode === 'dark' ?  '#191a1a': props.theme.palette.primary.main};
    color:white;
    text-align: center;
    padding:4px;
    margin-bottom: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 5px;
    margin: 5px 10px 5px 10px;

    >div{
        margin: 5px;
    }

    .default-state-button{
        position: absolute;
        right: 8px;
        top: 4px;
    }
    
}

`

export const ListItemHeadlineSection = styled.span`
   margin-left: 10px;
   margin-right: 10px;
`

export const PopoverWrapper = styled.div`
        background-color: white;
        height: 100px;
        width: 30%;
        min-width:150px;
        right: 0px;
        top: 95%;
        height: 150px;
        z-index: 500;
        position: absolute !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 8px 8px 0px;
        padding:6px 12px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        

        .close-popover{
            position:absolute;
            right:2px;
            top:2px;
        }
    `

export const MobileFooter = styled.div<{ theme: Theme}>`
        display: flex;
        justify-content: center; 
        z-index: 50; 
        position: fixed; 
        left: 0; 
        bottom: 0;
        width: 100%; 
        height: 50px;
        background-color: ${props => props.theme.palette.mode === 'dark' ? props.theme.palette.secondary.dark : props.theme.palette.primary.light };
        box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    `