export class MessageType {
    message: string = "";
    isError: boolean = false;
}

export class MessagService {
    static showSnackbar(message: string, isError: boolean) {
        let data = new MessageType()
        data.isError = isError;
        data.message = message;

        let event = new CustomEvent('showSnackbar', { detail: JSON.stringify(data) })
        window.dispatchEvent(event);
    }
    static showDialog(message: string, isError: boolean) {
        let data = new MessageType()
        data.isError = isError;
        data.message = message;

        let event = new CustomEvent('showDialog', { detail: JSON.stringify(data) })
        window.dispatchEvent(event);

    }

    static showLoader(message: string) {
        let event = new CustomEvent('showLoader', { detail: message })
        window.dispatchEvent(event);
    }

    static stopLoader() {
        let event = new CustomEvent('stopLoader')
        window.dispatchEvent(event);
    }
}