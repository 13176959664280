import Overview from "./Components/Overview";
import Navbar from "./Components/Navbar";
import styled, { css } from "styled-components";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { lazy, useEffect, Suspense } from "react";
import { UserModel } from "./Models/UserModel";
import UserContext from "./Contexts/UserContext";
import { useState } from "react";
import User from "./Components/User";
import { HashRouter } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import useMediaQuery from "./Hooks/useMediaQuery";
import { useServiceWorker } from "./Hooks/useServiceWorker";
import { GlobalStyles } from "./GlobalStyles/GlobalStyles";
import { NotFound } from "./Components/NotFound";
import { WeblistTag } from "./Models/WeblistTag";
import ApiService from "./Services/ApiService";
import WebListItem from "./Models/WebListItem";
import { MessageType } from "./Services/MessageService";
import { gapi } from "gapi-script";
import TagsContext from "./Contexts/TagsContext";
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface IAppContainer {
  isDesktop: boolean;
  loggedIn: boolean;
}

const AppContainer = styled.div<IAppContainer>`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Roboto" !important;

  .app-navbar {
    height: 80px;

    ${(props) =>
      !props.loggedIn &&
      css`
        display: none;
      `}
  }
  .app-content {
    height: 100%;
    ${(props) =>
      !props.isDesktop &&
      css`
        margin-top: 50px;
        margin-bottom: 50px;
      `}
  }
  .app-footer {
    height: 50px;
  }

  .one-state-list {
    justify-content: center;
  }
`;
function App() {
  const [user, setUser] = useState<UserModel | null | undefined>(null);
  const [loadingText, setLoadingText] = useState<string | null>(null);
  const [dialogText, setDialogText] = useState<string | null>(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const [errorSnack, setErrorSnack] = useState<string | null>(null);
  const [successSnack, setSuccessSnack] = useState<string | null>(null);

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [installPrompt, setInstallPrompt] = useState<any>(null);
  const [darkTheme, setDarkTheme] = useState(false);

  const [weblistTags, setWeblistTags] = useState<Array<WeblistTag>>([]);

  useEffect(() => {
    window.addEventListener("message", function (e) {
      if (!e.origin.includes("usermanagement.dk")) {
        return;
      }

      this.localStorage.setItem("weblistUser", e.data);
      setUser(e.data as UserModel);
    });

    getUser();
    redirectToHttps();

    getTheme();

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setInstallPrompt(e);
    });

    window.addEventListener("loggedOut", () => {
      setUser(undefined);
      localStorage.removeItem("weblistUser");
      localStorage.removeItem("weblistCategory");
    });

    window.addEventListener("showSnackbar", (event: Event) => {
      let data = JSON.parse((event as CustomEvent)?.detail) as MessageType;

      if (data.isError) {
        setErrorSnack(data.message);
      } else {
        setSuccessSnack(data.message);
      }
    });

    window.addEventListener("tagsUpdated", (event: Event) => {
      let data = JSON.parse((event as CustomEvent)?.detail) as WeblistTag[];
      setWeblistTags(data);
    });

    window.addEventListener("showLoader", (event: Event) => {
      let message = (event as CustomEvent)?.detail?.toString();
      if (message) {
        setLoadingText(message);
      }
    });

    window.addEventListener("stopLoader", () => {
      setLoadingText(null);
    });

    window.addEventListener("showDialog", (event: Event) => {
      let data = JSON.parse((event as CustomEvent)?.detail) as MessageType;
      if (data) {
        setDialogText(data.message);
      }
    });

    setTimeout(() => {
      gapi.load("client:auth2", () => {
        gapi.client.init({
          clientId:
            "675491310095-bu1d8drba592m8gh1fldc3olh7ai73ct.apps.googleusercontent.com",
          plugin_name: "chat",
        });
      });
    }, 200);
  }, []);

  useEffect(() => {
    let shown = sessionStorage.getItem("weblistPromptShown");
    if (shown) {
      return;
    }

    if (installPrompt) {
      setTimeout(() => {
        installPrompt.prompt();
        sessionStorage.setItem("weblistPromptShown", "true");
      }, 5000);
    }
  }, [installPrompt]);

  useEffect(() => {
    if (showReload && waitingWorker) {
      if (
        window.confirm(
          "Opdatering tilgængelig. Klik ok for at genindlæse siden"
        )
      ) {
        reloadPage();
      }
    }
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    if (user) {
      console.log("getting tags");
      setTimeout(() => {
        getWeblistTags();
      }, 200);
    }
  }, [user]);

  const getWeblistTags = async () => {
    if (user) {
      let tags = await ApiService.getWeblistTags(user.passwordHash);
      if (tags.isSuccessful) {
        setWeblistTags(tags.data as WeblistTag[]);
      }
    }
  };

  const getTheme = async () => {
    let theme = localStorage.getItem("weblistTheme");
    if (theme === "true") {
      setDarkTheme(true);
    } else {
      setDarkTheme(false);
    }
  };

  const getUser = () => {
    try {
      let user = localStorage.getItem("weblistUser");
      if (user) {
        let parsedUser = JSON.parse(user) as UserModel;

        if (parsedUser) {
          setUser(parsedUser);
          getWeblistTags();
          let event = new CustomEvent("userFound");
          window.dispatchEvent(event);
        }
      }
    } catch (exception) {
      //@ts-ignore
      // window.location = "https://" + window.location.hostname + "#/bruger";
    }
  };

  const redirectToHttps = () => {
    try {
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname.includes("192.")
      )
        return;

      if (
        window.location.protocol === "http:" ||
        !window.location.hostname.includes("www")
      ) {
        //@ts-ignore
        window.location =
          "https://www." + window.location.hostname + window.location.pathname;
      }
    } catch (exception) {}
  };

  const theme = createTheme({
    palette: {
      mode: darkTheme ? "dark" : "light",
      primary: {
        main: "#415a81",
        light: "ghostwhite",
        dark: "#202124",
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        dark: "#282a2e",
        contrastText: "#ffcc00",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  });

  const loginComplete = async (user: UserModel) => {
    try {
      if (user) {
        setUser(user);
        localStorage.setItem("weblistUser", JSON.stringify(user));
      }
    } catch (ex) {}
  };

  const toggleTheme = async () => {
    setDarkTheme((theme) => (theme = !theme));
    localStorage.setItem("weblistTheme", !darkTheme + "");
  };

  const AboutLazy = lazy(() => import("./Components/About"));
  const ArchiveLazy = lazy(() => import("./Components/Archive"));
  const ListSharingLazy = lazy(() => import("./Components/ListSharing"));
  const ResetLazy = lazy(() => import("./Components/ResetPassword"));
  const PrintLazy = lazy(() => import("./Components/Print"));
  const CreateUserLazy = lazy(() => import("./Components/CreateUser"));
  const SettingsLazy = lazy(() => import("./Components/Settings"));

  return (
    <GoogleOAuthProvider clientId="675491310095-bu1d8drba592m8gh1fldc3olh7ai73ct.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme} isDesktop={isDesktop} />
        <Snackbar
          open={errorSnack != null}
          autoHideDuration={5000}
          onClose={() => {
            setErrorSnack(null);
          }}
          message={errorSnack}
        >
          <Alert severity="error">{errorSnack}</Alert>
        </Snackbar>

        <Snackbar
          open={successSnack != null}
          autoHideDuration={5000}
          onClose={() => {
            setSuccessSnack(null);
          }}
          message={successSnack}
        >
          <Alert severity="success">{successSnack}</Alert>
        </Snackbar>

        <Dialog
          fullWidth={true}
          onClose={() => {
            setDialogText(null);
          }}
          open={dialogText !== null}
        >
          <DialogTitle>
            <ErrorIcon fontSize="large"></ErrorIcon>
            <Typography
              component={"span"}
              fontSize={20}
              sx={{
                display: "inline-block",
                verticalAlign: "super",
                marginLeft: "5px",
              }}
            >
              Fejl
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography component={"span"}>{dialogText}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogText(null);
              }}
            >
              Luk
            </Button>
          </DialogActions>
        </Dialog>

        <UserContext.Provider value={user}>
          <TagsContext.Provider value={weblistTags}>
            <HashRouter>
              <AppContainer isDesktop={isDesktop} loggedIn={!!user}>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loadingText !== null}
                  onClick={() => {
                    setLoadingText(null);
                  }}
                >
                  <CircularProgress color="inherit" />
                  <span style={{ marginLeft: "10px" }}>{loadingText}</span>
                </Backdrop>

                <Navbar toggleTheme={toggleTheme} />

                <Routes>
                  <Route
                    path="opret"
                    element={
                      <Suspense fallback={<></>}>
                        <CreateUserLazy
                          loginComplete={loginComplete}
                        ></CreateUserLazy>
                      </Suspense>
                    }
                  ></Route>
                  <Route
                    path="bruger"
                    element={
                      <User
                        toggleTheme={toggleTheme}
                        clearUser={async () => {
                          setUser(undefined);
                          localStorage.removeItem("weblistUser");
                          localStorage.removeItem("weblistCategory");
                        }}
                        loginComplete={loginComplete}
                      />
                    }
                  />
                  <Route
                    path="om"
                    element={
                      <Suspense fallback={<></>}>
                        <AboutLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <User
                        toggleTheme={toggleTheme}
                        clearUser={async () => {
                          setUser(undefined);
                          localStorage.removeItem("weblistUser");
                          localStorage.removeItem("weblistCategory");
                        }}
                        loginComplete={loginComplete}
                      />
                    }
                  />
                  <Route
                    path="oversigt"
                    element={
                      <Overview tags={weblistTags} setTags={setWeblistTags} />
                    }
                  ></Route>
                  <Route
                    path="arkiv"
                    element={
                      <Suspense fallback={<></>}>
                        <ArchiveLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="listedeling"
                    element={
                      <Suspense fallback={<></>}>
                        <ListSharingLazy></ListSharingLazy>
                      </Suspense>
                    }
                  />
                  <Route
                    path="nulstil"
                    element={
                      <Suspense fallback={<></>}>
                        <ResetLazy></ResetLazy>
                      </Suspense>
                    }
                  ></Route>
                  <Route
                    path="print"
                    element={
                      <Suspense fallback={<></>}>
                        <PrintLazy></PrintLazy>
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="indstillinger"
                    element={
                      <Suspense fallback={<></>}>
                        <SettingsLazy toggleTheme={toggleTheme}></SettingsLazy>
                      </Suspense>
                    }
                  /> */}
                  <Route path="*" element={<NotFound></NotFound>}></Route>
                </Routes>
              </AppContainer>
            </HashRouter>
          </TagsContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
