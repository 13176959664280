import { Autocomplete, Avatar, Badge, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, MenuItem, Popover, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { UserModel } from "../Models/UserModel";
import { Weblist } from "../Models/Weblist";
import { CategoryColorCircle, CategorySelectorWrapper } from "../StyledComponents/StyledComponents"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router";
import { CreateCategory, CreateTag } from "./Modals";
import ApiService from "../Services/ApiService";
import WebListItem from "../Models/WebListItem";
import { getStorage } from "../Services/StorageService";
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import ArchiveIcon from '@mui/icons-material/Archive';
import React from "react";
import styled from "styled-components";
import PrintIcon from '@mui/icons-material/Print';
import StyleIcon from '@mui/icons-material/Style';
import { WeblistTag } from "../Models/WeblistTag";
import { MessagService } from "../Services/MessageService";
import TagsContext from "../Contexts/TagsContext";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SettingsIcon from '@mui/icons-material/Settings';

export interface ICategorySelector {
    weblist: Array<Weblist>;
    weblistItems: Array<WebListItem>;
    user: UserModel | null | undefined;
    setWeblist: React.Dispatch<React.SetStateAction<Weblist[]>>
    weblistChanged: (event: any) => void;
    weblistArchiveCallback: (weblistId: number) => void
    weblistTags: WeblistTag[]
    filterChanged: (tags: string[], onlyOrdersWithComments: boolean, onlyOrdersWithUploads: boolean) => void;
}

const Base = styled.div`
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;

    .disabled-popover{
        pointer-events: none;
        opacity: 0.5;       
    }
`

const PopoverWrapper = styled(Base)`
    
`
const FilterWrapper = styled(Base)`
    width: 300px;
    min-height: 250px;

    section{
    text-align: left;
    padding-left: 15px;
    }
`

const CategorySelector = ({ filterChanged, weblistArchiveCallback, weblist, weblistItems, user, setWeblist, weblistChanged }: ICategorySelector) => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const [selectedWeblist, setSelectedWeblist] = useState<Weblist>(weblist[0])
    const [deleteCategory, setDeleteCategory] = useState<Weblist | null>(null)
    const [archiveCategory, setArchiveCategory] = useState<Weblist | null>(null)
    const [createCategoryModal, setCreateCategoryModal] = useState(false);
    const [categoryColor, setCategoryColor] = useState<string>("#FFFFFF")
    const [createTagModal, setCreateTagModal] = useState<boolean>(false)
    const tagsContext = useContext(TagsContext);

    const [filterTags, setFilterTags] = useState<string[]>([])
    const [onlyOrdersWithDetails, setOnlyOrdersWithDetails] = useState(false)
    const [onlyOrdersWithUploads, setOnlyOrdersWithUploads] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {

        window.addEventListener("resetCategory", () => {
            setSelectedWeblist(weblist[0])
        })
    }, [])

    useEffect(() => {
        filterChanged(filterTags, onlyOrdersWithDetails, onlyOrdersWithUploads)
    }, [filterTags, onlyOrdersWithDetails, onlyOrdersWithUploads])

    useEffect(() => {

        setTimeout(()=> {
        getSaved()
    }, 100)

    }, [weblist])

    const getSaved = async () => {
        if (weblist.length > 1) {

            let lastUsedCategory = await getStorage<Weblist>("weblistCategory")
            if (lastUsedCategory) {
                setSelectedWeblist(lastUsedCategory)
            }
        }
    }

    const handleWeblistChange = (event: any) => {
        let title = event.target.value;
        let target = weblist.find(item => item.title === title)
        if (target) {

            weblistChanged(target);
            setSelectedWeblist(target);
        }

    };

    function stringToColor(text: string) {
        try {
            let hash = 0;
            let i;

            /* eslint-disable no-bitwise */
            for (i = 0; i < text.length; i += 1) {
                hash = text.charCodeAt(i) + ((hash << 5) - hash);
            }

            let color = '#';

            for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.slice(-2);
            }
            /* eslint-enable no-bitwise */

            return color;
        } catch (ex) {
            return "#415a81";
        }

    }

    function stringAvatar(name: string) {

        if (name) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        }
    }

    const navigateToUserMenu = () => {
        navigate("/bruger");
    }

    const handleCreateCategory = async () => {

        if (user) {
            let category = (document.getElementById("createCategoryText") as HTMLInputElement)

            if (category.value?.length === 0) {
                MessagService.showSnackbar("Titel skal udfyldes", true)
                return;
            }

            //@ts-ignore
            let states = [...document.querySelectorAll(".weblist-column-title")].map(item => item.innerText);

            let result = await ApiService.createWeblistCategory(user.id, category.value, categoryColor, states)

            if (result.isSuccessful) {

                let parsed = result.data as Weblist
                if (parsed) {
                    //@ts-ignore
                    parsed.states = parsed.states.split(",")
                    setWeblist([...weblist, parsed])

                    setTimeout(() => {
                        setSelectedWeblist(parsed)
                        let event = new CustomEvent("weblistCreated", { detail: parsed })
                        window.dispatchEvent(event)
                    }, 50)

                    MessagService.showSnackbar("Liste oprettet med succes!", false)
                }
            }
            else {
                MessagService.showSnackbar("Det lykkedes ikke at oprette kategorien", true)
            }

            MessagService.stopLoader()
            category.value = ""
            setCreateCategoryModal(false)

        }
    }



    const getDeleteTitle = (id?: number) => {
        let title = `Vil du slette kategorien '${deleteCategory?.title}'?`;
        let attachedItems = weblistItems.filter(x => x.weblistId === id)
        if (attachedItems.length > 0) {
            title += ` Bemærk at der er noter tilknyttet, som vil blive slettet!`
        }
        return title;
    }

    const getArchiveTitle = (id?: number) => {
        let title = `Vil du arkivere kategorien '${archiveCategory?.title}'?`;
        let attachedItems = weblistItems.filter(x => x.weblistId === selectedWeblist.id && x.state > -1)
        if (attachedItems.length > 0) {
            title += ` Bemærk at der er noter tilknyttet, som vil blive arkiveret!`
        }
        return title;
    }

    const handleDeleteCategory = async () => {

        if (user && deleteCategory) {

            MessagService.showLoader("Sletter kategorien...")

            let result = await ApiService.deleteWeblistCategory(user.passwordHash, deleteCategory.id)
            if (result.isSuccessful) {
                let index = weblist.findIndex(item => item.id === deleteCategory.id)
                if (index > -1) {
                    let arr = [...weblist]
                    arr.splice(index, 1)
                    setWeblist(arr)

                    // await deleteStorage("weblistCategory")
                    localStorage.removeItem("weblistCategory")

                    setTimeout(() => {
                        setSelectedWeblist(weblist[0])
                        let event = new CustomEvent("customWeblistDeleted")
                        window.dispatchEvent(event)
                    }, 50)

                }

                MessagService.showSnackbar("Liste slettet med succes!", false);

            }
            else {
                MessagService.showSnackbar("Det lykkedes ikke at slette kategorien", true)
            }

            MessagService.stopLoader()
            setDeleteCategory(null)
        }
    }

    const handleArchiveCategory = async () => {

        if (user && archiveCategory) {
            let result = await ApiService.archiveCategory(user?.passwordHash, archiveCategory.id)
            if (result.isSuccessful) {

                weblistArchiveCallback(archiveCategory.id)

                localStorage.removeItem("weblistCategory")

                setTimeout(() => {
                    setSelectedWeblist(weblist[0])
                    let event = new CustomEvent("customWeblistDeleted")
                    window.dispatchEvent(event)
                }, 50)
            }
            else {
                MessagService.showSnackbar("Det lykkedes ikke at arkivere listen", true)
                console.log(result)
            }
            setArchiveCategory(null)
        }

    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [anchorFilter, setAnchorFilter] = React.useState<HTMLButtonElement | null>(null);

    const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilter(event.currentTarget);
        console.log(event)
    };

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const handleFilterClose = () => {
        setAnchorFilter(null);
    };

    const settingsPopoverOpen = Boolean(anchorEl);
    const filterPopoverOpen = Boolean(anchorFilter)



    return (
        <>

            <Dialog onClose={() => { setDeleteCategory(null) }} open={deleteCategory !== null}>
                <DialogTitle>Slet kategori?</DialogTitle>
                <DialogContent>
                    <Typography>{getDeleteTitle(deleteCategory?.id)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCategory}>Ja</Button>
                    <Button onClick={() => {
                        setDeleteCategory(null)
                    }}>Nej</Button>
                </DialogActions>

            </Dialog>

            <Dialog onClose={() => { setDeleteCategory(null) }} open={archiveCategory !== null}>
                <DialogTitle>Arkivér kategori?</DialogTitle>
                <DialogContent>
                    <Typography>{getArchiveTitle(deleteCategory?.id)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleArchiveCategory}>Ja</Button>
                    <Button onClick={() => {
                        setArchiveCategory(null)
                    }}>Nej</Button>
                </DialogActions>

            </Dialog>


            <CategorySelectorWrapper isDesktop={isDesktop} showDeleteIcon={selectedWeblist.title !== "Mine noter"}>

                <CreateCategory closeModal={() => { setCreateCategoryModal(false) }} categoryColor={categoryColor} setCategoryColor={setCategoryColor} createCategoryModal={createCategoryModal} setCreateCategoryModal={setCreateCategoryModal} handleCreateCategory={handleCreateCategory}></CreateCategory>

                <CreateTag tags={tagsContext} createTagModal={createTagModal} setCreateTagModal={setCreateTagModal}></CreateTag>

                <IconButton sx={{ margin: '5px', height: '40px' }} onClick={handleFilterClick}>
                    <Badge showZero={false} badgeContent={filterTags.length + (onlyOrdersWithDetails ? +1 : 0) + (onlyOrdersWithUploads ? +1 : 0)} color="primary">
                        <FilterAltIcon />
                    </Badge>
                </IconButton>
                <IconButton sx={{ margin: '5px', height: '40px' }} onClick={handleSettingsClick}>
                    <SettingsIcon />
                </IconButton>

                <Popover
                    id="filter-popover"
                    open={filterPopoverOpen}
                    anchorEl={anchorFilter}
                    onClose={handleFilterClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <FilterWrapper>
                        <Typography fontWeight={'bold'}>Filtrering</Typography>
                        <div>
                            <div style={{ marginBottom: '10px' }}>
                                <Autocomplete
                                    sx={{ width: 'calc(100% - 30px)', marginLeft: '15px' }}
                                    multiple
                                    limitTags={3}
                                    value={filterTags}
                                    options={tagsContext.map(item => item.title)}
                                    onChange={(e, value) => {
                                        setFilterTags(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Vælg tags" placeholder="Vælg tags" />
                                    )}

                                />
                            </div>
                            <section>
                                <div>
                                    <FormControlLabel onChange={(e, checked) => {
                                        setOnlyOrdersWithDetails(checked)
                                    }} control={<Checkbox checked={onlyOrdersWithDetails} />} label="Kun ordrer med detaljer" />
                                </div>
                                <div>
                                    <FormControlLabel onChange={(e, checked) => {
                                        setOnlyOrdersWithUploads(checked)
                                    }} control={<Checkbox checked={onlyOrdersWithUploads} />} label="Kun ordrer med vedhæftede filer" />
                                </div>
                            </section>
                        </div>
                    </FilterWrapper>
                </Popover>

                <Popover
                    id="category-popover"
                    open={settingsPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleSettingsClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <PopoverWrapper className="category-buttons">

                        <div onClick={() => {
                            handleSettingsClose()
                            setCreateCategoryModal(true)
                        }} >
                            <IconButton title="Arkivér liste">
                                <AddCircleOutlineIcon />
                            </IconButton>
                            <Typography fontSize={'12px'}>tilføj ny</Typography>
                        </div>
                        <div onClick={() => {
                            handleSettingsClose()
                            setArchiveCategory(selectedWeblist)
                        }} className={selectedWeblist.title === 'Mine noter' ? 'disabled-popover' : ''}>
                            <IconButton title="Arkivér liste">
                                <ArchiveIcon />
                            </IconButton>
                            <Typography fontSize={'12px'}>arkivér liste</Typography>
                        </div>
                        <div onClick={() => {
                            handleSettingsClose()
                            let target = weblist.find(item => item.title === selectedWeblist.title)
                            if (target) {
                                setDeleteCategory(target)
                            }
                        }} className={selectedWeblist.title === 'Mine noter' ? 'disabled-popover' : ''}>
                            <IconButton >
                                <DeleteForeverIcon />
                            </IconButton>
                            <Typography fontSize={'12px'}>slet liste</Typography>
                        </div>
                        <div onClick={() => {

                            sessionStorage.setItem("weblistItems", JSON.stringify(weblistItems))
                            let target = weblist.find(item => item.title === selectedWeblist.title)
                            sessionStorage.setItem("weblist", JSON.stringify(target))

                            handleSettingsClose()
                            navigate("/print")
                        }} className={selectedWeblist.title === 'Mine noter' ? 'disabled-popover' : ''}>
                            <IconButton >
                                <PrintIcon />
                            </IconButton>
                            <Typography fontSize={'12px'}>printvisning</Typography>
                        </div>


                        <div onClick={() => {

                            handleSettingsClose()
                            setCreateTagModal(true)
                        }}>
                            <IconButton >
                                <StyleIcon />
                            </IconButton>
                            <Typography fontSize={'12px'}>tags</Typography>
                        </div>

                    </PopoverWrapper>
                </Popover>


                <FormControl className="categorySelect">
                    <InputLabel>lister</InputLabel>
                    <Select
                        value={selectedWeblist.title}
                        label="Webliste"
                        onChange={handleWeblistChange}
                    >
                        {weblist.map((item, index) =>
                            <MenuItem key={index} className="category-item" value={item.title}> <CategoryColorCircle color={item.color}></CategoryColorCircle>{item.title} {(item.userId !== user?.id && item.title !== "Mine noter") ? <PeopleAltTwoToneIcon sx={{ fontSize: '16px', marginLeft: '5px' }} /> : ''}</MenuItem>
                        )}

                    </Select>
                </FormControl>
                {(user && isDesktop) &&
                    <IconButton onClick={navigateToUserMenu} size="large">
                        <Avatar {...stringAvatar(user.name)} />
                    </IconButton>
                }
                {(!user && isDesktop) &&
                    <IconButton title="Log ind" onClick={navigateToUserMenu} size="large">
                        <AccountCircleIcon fontSize="large"></AccountCircleIcon>
                    </IconButton>

                }
            </CategorySelectorWrapper>
        </>
    )
}

export default CategorySelector;