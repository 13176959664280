import { BaseDtoModel } from "./BaseDtoModel";

export class PostWeblistModel extends BaseDtoModel {
    title: string = "";
    details: string = "";
    userId: string = "";
    state: number = 0;
    weblistId?: number = undefined;
    deadline: string = "";
    pinned: number = -1;

}