import { Typography, IconButton } from "@mui/material";
import { UserModel } from "../Models/UserModel";
import WebListItem from "../Models/WebListItem";
import ApiService from "../Services/ApiService";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useContext, useEffect, useState } from "react";
import UserContext from "../Contexts/UserContext";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import styled from "styled-components";

export interface IUploadView {
    item: WebListItem
    deleteCallback: (item: WebListItem) => void
    uploadCallback: (item: WebListItem, fileName: string) => void
}

const StyledImage = styled.img`
    
    max-width: 400px;
    margin-left: 10px;

    @media only screen and (max-width: 768px) {
        max-width: 90% !important; 
    }

`

const ImagePropsWrapper = styled.div`

    margin-left: 10px;
    >div{
        font-size: 12px; 
    }
`

class ImageProps {
    naturalWidth: number;
    naturalHeight: number;
    title: string;

    constructor(naturalWidth: number, naturalHeight: number, title: string) {
        this.naturalHeight = naturalHeight
        this.naturalWidth = naturalWidth
        this.title = title
    }

}

const Upload = ({ item, deleteCallback, uploadCallback }: IUploadView) => {

    const userContext = useContext(UserContext)
    const [selectedUpload, setSelectedUpload] = useState<FormData | null>(null)
    const [selectedUploadTitle, setSelectedUploadTitle] = useState<string | null>(null)
    const [imageProps, setImageProps] = useState<null | ImageProps>(null)
    const [uploadProgress, setUploadProgress] = useState<number>(0)

    useEffect(() => {

        if (uploadProgress === 100 && userContext) {

            generatePreview(item, userContext)

        }
        else if (item.upload && userContext) {
            generatePreview(item, userContext)
        }

    }, [uploadProgress, item])

    const generatePreview = (item: WebListItem, user: UserModel) => {

        let type = "";

        if (!selectedUploadTitle) {
            type = item.upload.substring(item.upload.length - 3, item.upload.length)
        }
        else {
            type = selectedUploadTitle.substring(selectedUploadTitle.length - 3, selectedUploadTitle.length)
        }

        if (type.toLowerCase() === "jpg" || type.toLowerCase() === "png") {

            return <>
                <StyledImage onLoad={() => {
                    let image = (document.getElementById("uploadedImage") as HTMLImageElement)
                    if (image) {
                        let title = image.src.substring(image.src.lastIndexOf("/") + 1, image.src.length - 4)
                        setImageProps(new ImageProps(image.naturalWidth, image.naturalHeight, title))
                    }
                }} src={`https://www.thomasrasmussen.dk/Uploads/Weblist/${user.id.toString()}/${item.id.toString()}/${item.upload ? item.upload : selectedUploadTitle}`} id="uploadedImage"></StyledImage>

                {imageProps &&
                    <ImagePropsWrapper>
                        <div>
                            {imageProps.title}
                        </div>
                        <div>
                            {imageProps.naturalWidth}px / {imageProps.naturalHeight}px
                        </div>
                    </ImagePropsWrapper>
                }

                <a style={{ visibility: 'hidden' }} id="downloadLink" download target={'_blank'} href={`https://www.thomasrasmussen.dk/Uploads/Weblist/${user.id.toString()}/${item.id.toString()}/${item.upload}`}>download</a>
            </>
        }
        else {
            return <>
                <a download style={{ visibility: 'hidden' }} target={'_blank'} href={`https://www.thomasrasmussen.dk/Uploads/Weblist/${user.id.toString()}/${item.id.toString()}/${item.upload}`} id="uploadedFile">download</a>
                <Typography marginLeft={'10px'} id="uploadedFileText">{item?.upload?.toString()}</Typography>
            </>
        }
    }

    return (
        <>
            <>
                <>
                    <IconButton onClick={() => {
                        document.getElementById("fileUpload")?.click()
                    }}>
                        <AttachmentIcon />

                    </IconButton>
                    {item && item?.upload?.length > 0 &&
                        <>

                            <IconButton onClick={() => {
                                document.getElementById("downloadLink")?.click()
                                document.getElementById("uploadedFile")?.click()
                            }}>
                                <CloudDownloadIcon />
                            </IconButton>

                            <IconButton onClick={async () => {
                                if (!item) {
                                    return;
                                }

                                let result = await ApiService.deleteUpload(userContext?.id!, item?.id)
                                if (result.isSuccessful) {
                                    deleteCallback(item)

                                    setImageProps(null)
                                }
                                else {

                                }
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </>

                    }
                </>

                <input style={{ display: 'none' }} onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {

                        let formData = new FormData();
                        formData.append("file", e.target.files[0])

                        setSelectedUpload(formData)
                        setSelectedUploadTitle(e.target.files[0].name)
                    }

                }} type={'file'} id="fileUpload"></input>

                {selectedUpload !== null &&
                    <>
                        <IconButton onClick={async () => {
                            if (!item || !userContext) {
                                return;
                            }

                            let result = await ApiService.upload(selectedUpload, userContext.id!, item.id, (progress) => {

                                let percent = Math.abs(progress.loaded * 100 / progress.total)
                                setUploadProgress(percent)

                            })

                            if (result.isSuccessful) {
                                uploadCallback(item, result.data as string)

                                setSelectedUpload(null)
                                setSelectedUploadTitle(null)
                            }
                            else {

                            }
                        }}>
                            <FileUploadIcon />
                        </IconButton>

                        {(uploadProgress > 0 && uploadProgress < 100) && <div>
                            <Typography display={'flex'}>{uploadProgress.toFixed(0)} %</Typography>
                        </div>}
                        {selectedUploadTitle}
                    </>


                }
            </>
            <>
                {item?.upload && item.upload?.length > 0 &&
                    <>
                        {generatePreview(item, userContext!)}
                    </>

                }
            </>
        </>
    )
}

export default Upload;