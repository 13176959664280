import React, { useCallback } from "react";
import {
  Popover,
  Badge,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { GetWeblistModel } from "../Models/Dto/GetWeblistModel";
import { EditWeblistModel } from "../Models/Dto/EditWeblistModel";
import { PostWeblistModel } from "../Models/Dto/PostWeblistModel";
import useMediaQuery from "../Hooks/useMediaQuery";
import PushPinIcon from "@mui/icons-material/PushPin";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  CardWrapper,
  ListItemButtons,
  ListContainerWrapper,
  CategoryColorCircle,
  BoxWrapper,
  PopoverWrapper,
  MobileFooter,
} from "../StyledComponents/StyledComponents";
import CircleIcon from "@mui/icons-material/Circle";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//Custom imports
import CategorySelector from "./CategorySelector";
import {
  formatDateTime,
  getFormattedDeadline,
  getTimeToDeadline,
} from "../Services/UtilsService";
import { IListContainer } from "../Interfaces/IListContainer";
import { Weblist } from "../Models/Weblist";
import IComponent from "../Interfaces/IComponent";
import WebListItem from "../Models/WebListItem";
import ApiService from "../Services/ApiService";
import UserContext from "../Contexts/UserContext";
import { setStorage, getStorage } from "../Services/StorageService";
import EditDialog from "./EditDialog";
import { DefaultStateModel } from "../Models/DefaultStateModel";
import { WeblistTag } from "../Models/WeblistTag";
import { DeleteWeblistCategoryModel } from "../Models/Dto/DeleteWeblistCategoryModel";
import { MessagService } from "../Services/MessageService";
import NotLoggedIn from "./NotLoggedIn";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ArchiveIcon from "@mui/icons-material/Archive";
import MultiSelectComponent from "./MultiSelectComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export interface IOverviewProps extends IComponent {
  setTags: React.Dispatch<React.SetStateAction<WeblistTag[]>>;
  tags: WeblistTag[];
}

function Overview(props: IOverviewProps) {
  const isDesktop = useMediaQuery("(min-width: 1400px)");
  const theme = useTheme();

  const userContext = useContext(UserContext);
  const [weblistItems, setWeblistItems] = useState<Array<WebListItem>>([]);
  const [selectedEdit, setSelectedEdit] = useState<number>(0);
  const [weblistCategories, setWeblistCategories] = useState<Array<Weblist>>([
    new Weblist("Mine noter", ["Planlagt", "Aktiv", "Udført"]),
  ]);
  const [selectedWeblist, setSelectedWeblist] = useState<Weblist>(
    weblistCategories[0]
  );
  const [deadlineActive, setDeadlineActive] = useState(false);
  const [editItem, setEditItem] = useState<WebListItem | null>(null);
  const [moveItem, setMoveItem] = useState<WebListItem | null>(null);

  const [defaultStates, setDefaultStates] = useState<Array<DefaultStateModel>>(
    []
  );

  const [activeFilterTags, setActiveFilterTags] = useState<string[]>([]);
  const [onlyOrdersWithDetails, setOnlyOrdersWithDetails] = useState(false);
  const [onlyOrdersWithUploads, setOnlyOrdersWithUploads] = useState(false);

  const [contextItem, setContextItem] = useState<null | WebListItem>(null);

  const [multiSelectState, setMultiSelectState] = useState(false);
  const [multiSelectValues, setMultiSelectValues] = useState<Array<number>>([]);

  const [isNewNote, setIsNewNote] = useState(false);
  const [newItemAdded, setNewItemAdded] = useState(false);

  const [anchorFilter, setAnchorFilter] =
    React.useState<HTMLButtonElement | null>(null);

  //useEffects
  useEffect(() => {
    window.addEventListener("weblistCreated", (e) => {
      setTimeout(() => {
        let target = (e as CustomEvent).detail as Weblist;
        setSelectedWeblist(target);
      }, 10);
    });

    window.addEventListener("customWeblistDeleted", (e) => {
      setTimeout(() => {
        setSelectedWeblist(weblistCategories[0]);
      }, 10);
    });

    let stored = localStorage.getItem("weblistDefaultStates");
    if (stored) {
      let parsed = JSON.parse(stored) as Array<DefaultStateModel>;
      if (parsed) {
        setDefaultStates(parsed);
      }
    }

    window.addEventListener("click", function (e: any) {
      if (!document.getElementById("popover-box")?.contains(e.target)) {
        setContextItem(null);
      }
    });

    // setTimeout(() => {
    //     let button = document.getElementById("addNoteButton") as HTMLButtonElement;
    //     if (button) {
    //         button.focus();
    //     }
    // }, 200);
  }, []);

  useEffect(() => {
    if (newItemAdded) {
      setNewItemAdded(false);
      setTimeout(() => {
        document
          .querySelector(`[data-id="${weblistItems[0].id}"]`)
          ?.classList.add("bounce-top");
      }, 10);
    }
  }, [weblistItems]);

  useEffect(() => {
    getWeblistItems(selectedWeblist.id);
  }, [selectedWeblist]);

  useEffect(() => {
    if (userContext) {
      getWeblistCategories();
    }
  }, [userContext]);

  //functions
  const getWeblistItems = useCallback(
    async (categoryId?: number, archive: boolean = false) => {
      if (!userContext) {
        return;
      }

      MessagService.showLoader("Henter data...");
      let model = new GetWeblistModel();
      model.archive = archive;
      model.passwordHash = userContext.passwordHash;

      if (categoryId && categoryId > 0) {
        model.weblistId = categoryId;
      }

      let result = await ApiService.getAllWeblists(model);

      if (result.isSuccessful && result.data) {
        let arr = result.data as Array<WebListItem>;
        setWeblistItems(arr);
      }

      MessagService.stopLoader();
    },
    [selectedWeblist]
  );

  const getWeblistCategories = async () => {
    try {
      if (!userContext) {
        return;
      }

      let result = await ApiService.getWeblistCategories(userContext.id);
      if (result.isSuccessful) {
        let arr = result.data as Array<Weblist>;

        if (arr) {
          arr.map((item) => {
            //@ts-ignore
            item.states = item.states.split(",");
          });

          if (!arr.some((x) => x.title === "Mine noter")) {
            arr.unshift(
              new Weblist("Mine noter", ["Planlagt", "Aktiv", "Udført"])
            );
          }

          setWeblistCategories(arr);
        }
        let lastUsedCategory = await getStorage<Weblist>("weblistCategory");
        if (lastUsedCategory) {
          //@ts-ignore
          if (typeof lastUsedCategory.states === "string") {
            //@ts-ignore
            lastUsedCategory.states = [...lastUsedCategory.states.join(",")];
          }
          setSelectedWeblist(lastUsedCategory);
        } else {
          setSelectedWeblist(
            new Weblist("Mine noter", ["Planlagt", "Aktiv", "Udført"])
          );
        }
      }
    } catch (exception) {
      MessagService.showSnackbar(
        "Fejl under hentning af lister. Prøv igen",
        true
      );
      console.log("getWeblistCategories exception: ", exception);
    }
  };

  const handleSubmit = async (
    title: string,
    details: string,
    deadline: string
  ) => {
    if (userContext) {
      let model = new PostWeblistModel();
      model.details = details;
      model.state = getDefaultState(selectedWeblist.id);
      model.title = title;
      model.userId = userContext?.id.toString();
      model.passwordHash = userContext.passwordHash;
      model.deadline = deadline;
      model.pinned = -1;
      model.weblistId = selectedWeblist?.id;

      let result = await ApiService.postWeblist(model);
      if (result.isSuccessful) {
        setNewItemAdded(true);
        let list = result.data as Array<WebListItem>;
        setWeblistItems(list);
      }

      setEditItem(null);
      setIsNewNote(false);
    }
  };

  const changeState = useCallback(
    async (item: WebListItem | null, state: number) => {
      if (item === null || !userContext) {
        return;
      }

      let model = new EditWeblistModel();
      model.details = item.details;
      model.passwordHash = userContext.passwordHash;
      model.state = state;
      model.title = item.title;
      model.weblistId = item.id;

      let index = weblistItems.findIndex((x) => x.id == item.id);

      let result = await ApiService.editWeblist(model);

      if (result.isSuccessful) {
        let target = result.data as WebListItem;
        removeAndInsert(index, target, true);

        if (target) {
          setTimeout(() => {
            document
              .querySelector(`[data-id="${item.id}"]`)
              ?.classList.add("bounce-top");
          }, 10);
        }
      }
    },
    [weblistItems]
  );

  const setDefaultState = (weblistId: number, state: number) => {
    let list = [...defaultStates];

    let existing = list.findIndex((item) => item.weblistId === weblistId);

    if (existing > -1) {
      list.splice(existing, 1);
    }

    let model = new DefaultStateModel();
    model.state = state;
    model.weblistId = weblistId;

    list.push(model);

    setDefaultStates(list);

    localStorage.setItem("weblistDefaultStates", JSON.stringify(list));
  };

  const isDefaultState = (weblistId: number, state: number) => {
    if (!defaultStates.some((x) => x.weblistId === weblistId) && state === 1) {
      return true;
    }

    return (
      defaultStates.find(
        (item) => item.state === state && item.weblistId === weblistId
      ) !== undefined
    );
  };

  const getDefaultState = (weblistId: number) => {
    let target = defaultStates.find((item) => item.weblistId === weblistId);
    return target === undefined ? 1 : target.state;
  };

  //FUNCTIONAL
  const ListContainer = (props: IListContainer) => {
    return (
      <ListContainerWrapper
        id={`state${props.state}`}
        deadlineActive={deadlineActive}
        onClick={() => {
          setDefaultState(props.weblistId, props.state);
        }}
        theme={theme}
        isDesktop={isDesktop}
        maxWidth={props.maxWidth}
        showClearAllIcon={true}
        className="draggable-container"
        data-state={props.state}
      >
        <div className={`listcontainer-header id${props.weblistId}`}>
          <IconButton
            onClick={() => {
              setDefaultState(props.weblistId, props.state);
            }}
            className="default-state-button"
          >
            {isDefaultState(props.weblistId, props.state) ? (
              <CheckCircleIcon className="state-icon" />
            ) : (
              <></>
            )}
          </IconButton>
          <div>{props.title}</div>
        </div>
        {props.children}
      </ListContainerWrapper>
    );
  };

  const togglePinned = useCallback(
    async (id: number) => {
      let target = weblistItems.find((item) => item.id == id);

      if (!userContext) {
        return;
      }

      if (target) {
        let result = await ApiService.togglePinned(
          userContext.passwordHash,
          id
        );

        target.pinned = result.data;

        let index = weblistItems.findIndex((item) => item.id == id);

        removeAndInsert(index, target);
      }
    },
    [weblistItems]
  );

  const handleDelete = async (item: WebListItem) => {
    if (!userContext) {
      return;
    }

    if (item && userContext) {
      let model = new DeleteWeblistCategoryModel();
      model.passwordHash = userContext.passwordHash;
      model.weblistId = item.id;

      let result = await ApiService.deleteWeblistItem(
        model.passwordHash,
        model.weblistId
      );
      if (result.isSuccessful) {
        setWeblistItems([...weblistItems.filter((x) => x.id !== item.id)]);
        MessagService.showSnackbar("Note slettet med succes!", false);
      } else {
        MessagService.showSnackbar(
          "Der opstod en fejl under sletning, prøv igen.",
          true
        );
      }
    }
  };

  const removeAndInsert = (
    index: number,
    item: WebListItem,
    unshift?: boolean,
    isDeleted?: boolean
  ) => {
    let arr = [...weblistItems];
    let idx = weblistItems.findIndex((current) => current.id === item.id);
    arr.splice(idx, 1);
    if (unshift && !isDeleted) {
      arr.unshift(item);
    } else if (!isDeleted) {
      arr.push(item);
    }

    setWeblistItems([...arr]);
  };

  const getListItemColor = (weblistId: number | undefined): string => {
    let target = weblistCategories.find((x) => x.id === weblistId);
    if (target) {
      return target.color;
    }
    return "#FFFFFF";
  };

  const archiveWeblistItem = async (target: WebListItem) => {
    if (!userContext) {
      return;
    }

    let result = await ApiService.archive(userContext.passwordHash, target.id);

    if (result.isSuccessful) {
      let arr = [...weblistItems];
      let index = weblistItems.findIndex((x) => x.id === target?.id);
      arr.splice(index, 1);
      setWeblistItems(arr);

      MessagService.showSnackbar("Note arkiveret!", false);
    } else {
      MessagService.showSnackbar(
        "Der opstod en fejl under arkivering, prøv igen.",
        true
      );
    }
  };

  const RenderTags = useCallback((tags: string, hasDetails: boolean) => {
    let tagsArray = tags.split(",");

    return (
      <div style={{ position: "absolute", bottom: "2px", left: "8px" }}>
        {tagsArray.map((item) => {
          return (
            <Chip
              sx={{ margin: "5px 2px", height: "22px" }}
              label={item}
              variant="outlined"
            />
          );
        })}
      </div>
    );
  }, []);

  const handleChangeSortOrder = async (item: WebListItem, sortUp: boolean) => {
    if (!userContext) {
      return;
    } else {
      let result = await ApiService.changeSortOrder(
        userContext.passwordHash!,
        item.id,
        sortUp
      );
      if (result.isSuccessful) {
        let target = weblistItems.find((x) => x.id === item.id);

        if (target) {
          let arr = [...weblistItems.filter((x) => x.id !== item.id)];
          sortUp ? arr.unshift(target) : arr.push(target);
          setWeblistItems(arr);
        }
      }
    }
  };

  const handleContextMenu = (item: WebListItem) => {
    setContextItem(item);
  };

  const PopoverElement = (item: WebListItem) => {
    return (
      <PopoverWrapper
        style={{
          display: item.id === contextItem?.id ? "flex" : "none",
          backgroundColor: theme.palette.mode === "dark" ? "#161616" : "white",
        }}
        id="popover-box"
      >
        <IconButton
          size="small"
          className="close-popover"
          onClick={() => {
            setContextItem(null);
          }}
        >
          <CloseIcon />
        </IconButton>
        <Button
          onClick={() => togglePinned(item.id)}
          variant="text"
          startIcon={<PushPinIcon />}
        >
          {item.pinned > -1 ? "frigør" : "fastgør"}
        </Button>
        <Button
          onClick={() => {
            setMoveItem(item);
          }}
          variant="text"
          startIcon={<DriveFileMoveIcon />}
        >
          flyt
        </Button>
        <Button
          onClick={() => {
            archiveWeblistItem(item);
          }}
          variant="text"
          startIcon={<ArchiveIcon />}
        >
          arkivér
        </Button>
        <Button
          onClick={() => {
            handleDelete(item);
          }}
          variant="text"
          startIcon={<DeleteIcon />}
        >
          slet
        </Button>
      </PopoverWrapper>
    );
  };

  const ListItemButtonsComponent = (item: WebListItem) => {
    return (
      <ListItemButtons className="list-item-buttons">
        <div>
          <span>
            {selectedWeblist.states.map((innerItem, innerIndex) => {
              return (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    changeState(item, innerIndex + 1);
                  }}
                  disabled={item.state === innerIndex + 1}
                  size="small"
                  key={innerItem + "_" + innerIndex}
                >
                  {innerItem}
                </Button>
              );
            })}
          </span>
          <span style={{ margin: "0 10px 0 10px" }}></span>
          <span>
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleChangeSortOrder(item, true);
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleChangeSortOrder(item, false);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
        
              <IconButton
                sx={{ width: "40px", height: "40px" }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleContextMenu(item);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            
          </span>
        </div>
      </ListItemButtons>
    );
  };

  const ListItemButtonsMemo = React.memo(ListItemButtonsComponent);

  const ListItem = (item: WebListItem) => {
    return (
      <CardWrapper
        onClick={(e) => {
          e.preventDefault();
          if (contextItem === null && !multiSelectState) {
            setEditItem(item);
          }

          if (multiSelectState) {
            if (multiSelectValues.indexOf(item.id) > -1) {
              setMultiSelectValues([
                ...multiSelectValues.filter((x) => x !== item.id),
              ]);
            } else {
              setMultiSelectValues([...multiSelectValues, item.id]);
            }
          }
        }}
        isDarkTheme={theme.palette.mode === "dark"}
        theme={theme}
        key={item.id.toString()}
        className="list-item"
        isDesktop={isDesktop}
        id={item.id.toString()}
      >
        <Card
          variant="elevation"
          data-id={item.id.toString()}
          data-item={item.title}
          className={`note-list-item ${
            multiSelectValues.indexOf(item.id) > -1 && "multi-select"
          }`}
        >
          <CardContent sx={{ paddingLeft: "12px", paddingTop: "16px" }}>
            <div
              style={{
                fontSize: "13px",
                display: "flex",
                marginTop: "-10px",
                marginBottom: "5px",
                marginLeft: "-5px",
              }}
            >
              <Typography
                sx={{ fontSize: 13 }}
                color="text.secondary"
                gutterBottom
              >
                {weblistCategories.findIndex((x) => x.id == item.weblistId) >
                  0 && (
                  <span className="category-circle">
                    <CircleIcon
                      sx={{
                        color: getListItemColor(item.weblistId),
                        fontSize: "1rem",
                        marginRight: "2px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    />
                  </span>
                )}

                {item.userId != userContext?.id.toString() && (
                  <span style={{ marginRight: "2px", marginLeft: "2px" }}>
                    <IconButton
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        fontSize: "1rem",
                        marginRight: "2px",
                        marginLeft: "2px",
                      }}
                      size="small"
                    >
                      <PeopleAltTwoToneIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </span>
                )}
                {item.pinned >= 0 && (
                  <span style={{ marginRight: "2px", marginLeft: "2px" }}>
                    <IconButton
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        fontSize: "1rem",
                        marginRight: "2px",
                        marginLeft: "2px",
                      }}
                      size="small"
                    >
                      <PushPinIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </span>
                )}
                <span title={formatDateTime(item.dateCreated, true)}>
                  <Chip
                    size="small"
                    label={formatDateTime(item.dateCreated)}
                  ></Chip>
                </span>
                {item.deadline?.length > 0 && item.state !== 3 && (
                  <span
                    title={`Deadline ${getFormattedDeadline(item.deadline)}`}
                    style={{ marginRight: "2px", marginLeft: "2px" }}
                  >
                    <Chip
                      label={getTimeToDeadline(item.deadline)}
                      size="small"
                      icon={<NotificationsActiveIcon />}
                    />
                  </span>
                )}
              </Typography>
            </div>
            <Typography
              sx={{ fontSize: 16, paddingBottom: "15px" }}
              color="text.secondary"
              gutterBottom
            >
              {item.title}
            </Typography>
            {item.tags?.length > 0 &&
              RenderTags(item.tags, item.details.length > 0)}
            
          </CardContent>
          {isDesktop && selectedEdit !== item.id && (
            <ListItemButtonsMemo {...item}></ListItemButtonsMemo>
          )}
          {!isDesktop && 
            <IconButton
              sx={{ width: "40px", height: "40px", position:'absolute', right:'0', bottom:'5px' }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleContextMenu(item);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            }
        </Card>
        <PopoverMemo key={item.id.toString()} {...item} />
      </CardWrapper>
    );
  };

  const handleWeblistChange = async (event: any) => {
    let weblist = event as Weblist;
    setSelectedWeblist(weblist);
    await setStorage<Weblist>("weblistCategory", weblist);
  };

  const showSpacer = (items: Array<WebListItem>, state: number) => {
    let filter = items.some((item) => item.state === state && item.pinned > -1);
    return filter ? <div style={{ marginTop: "30px" }}></div> : "";
  };

  const handleMove = async (weblistId: number) => {
    if (!moveItem || !userContext) {
      return;
    }

    let result = await ApiService.moveWeblist(
      userContext.passwordHash,
      weblistId,
      moveItem.id
    );

    if (result.isSuccessful) {
      let target = result.data as WebListItem;

      setWeblistItems([...weblistItems.filter((x) => x.id !== target.id)]);

      setMoveItem(null);
      MessagService.showSnackbar("Note flyttet med succes", false);
    } else {
      MessagService.showSnackbar(
        "Det lykkedes ikke at flytte noten, prøv igen.",
        true
      );
    }
  };

  const handleWeblistArchived = (weblistId: number) => {
    setWeblistCategories(weblistCategories.filter((x) => x.id !== weblistId));
    setWeblistItems(weblistItems.filter((x) => x.weblistId !== weblistId));
    setSelectedWeblist(weblistCategories[0]);
  };

  const handleFilterChanged = (
    tags: string[],
    onlyOrdersWithDetails: boolean,
    onlyOrdersWithUploads: boolean
  ) => {
    setActiveFilterTags(tags);
    setOnlyOrdersWithDetails(onlyOrdersWithDetails);
    setOnlyOrdersWithUploads(onlyOrdersWithUploads);
  };

  const filterTags = (item: WebListItem) => {
    if (activeFilterTags.length === 0) return true;

    for (let tag of activeFilterTags) {
      if (item.tags?.length > 0 && item.tags.indexOf(tag) > -1) {
        return true;
      }
    }
    return false;
  };

  const filterOnlyOrdersWithDetails = (item: WebListItem) => {
    if (!onlyOrdersWithDetails) return true;

    if (onlyOrdersWithDetails && item.details.length > 0) {
      return true;
    }

    return false;
  };

  const filterOnlyOrdersWithUploads = (item: WebListItem) => {
    if (!onlyOrdersWithUploads) return true;

    if (onlyOrdersWithUploads && item.upload?.length > 0) {
      return true;
    }

    return false;
  };

  const ListItemMemo = React.memo(ListItem);

  const PopoverMemo = React.memo(PopoverElement);

  return (
    <>
      {!userContext ? (
        <>
          <NotLoggedIn />
        </>
      ) : (
        <>
          <div className="app-content">
            <CategorySelector
              filterChanged={handleFilterChanged}
              weblistTags={props.tags}
              weblistArchiveCallback={handleWeblistArchived}
              weblistItems={weblistItems}
              weblist={weblistCategories}
              user={userContext}
              setWeblist={setWeblistCategories}
              weblistChanged={handleWeblistChange}
            ></CategorySelector>

            <Dialog
              onClose={() => {
                setMoveItem(null);
              }}
              open={moveItem !== null}
            >
              <DialogTitle>Flyt note til anden liste</DialogTitle>
              <DialogContent>
                {weblistCategories.map((item) => {
                  return (
                    <div>
                      <CategoryColorCircle
                        color={item.color}
                      ></CategoryColorCircle>
                      <Button
                        disabled={item.title === selectedWeblist.title}
                        onClick={() => {
                          handleMove(item.id);
                        }}
                      >
                        {item.title}
                      </Button>
                    </div>
                  );
                })}
              </DialogContent>
            </Dialog>

            <EditDialog
              setIsNewNote={setIsNewNote}
              handleSubmit={handleSubmit}
              isNewNote={isNewNote}
              moveCallback={(e) => {
                setMoveItem(e);
              }}
              deleteCallback={handleDelete}
              archiveCallback={archiveWeblistItem}
              editItem={editItem}
              setEditItem={setEditItem}
              weblistItems={weblistItems}
              selectedWeblist={selectedWeblist}
              editCallback={(item) => {}}
              changeStateCallback={changeState}
              handleSaveEditCallback={(index, item, unshift) => {
                removeAndInsert(index, item, unshift);
              }}
              togglePinnedCallback={(item) => {
                togglePinned(item);
              }}
            ></EditDialog>

            <div
              style={{ display: "flex", marginLeft: "15px" }}
              className={`${
                selectedWeblist.states.length === 1 ? "one-state-list" : ""
              }`}
            >
              <IconButton
                id="addNoteButton"
                tabIndex={1}
                onClick={() => {
                  setIsNewNote(true);
                  setEditItem(new WebListItem());
                }}
              >
                <AddCircleIcon color="primary" fontSize="medium" />
              </IconButton>

              <MultiSelectComponent
                setMultiSelectValues={setMultiSelectValues}
                multiSelectValues={multiSelectValues}
                selectedWeblist={selectedWeblist}
                weblistItems={weblistItems}
                setWeblistItems={setWeblistItems}
                multiSelectState={multiSelectState}
                setMultiSelectState={setMultiSelectState}
              ></MultiSelectComponent>
            </div>
            <BoxWrapper isDesktop={isDesktop}>
              {selectedWeblist?.states?.length > 0 && (
                <>
                  {selectedWeblist.states.map((weblistItem, weblistIndex) => {
                    return (
                      <ListContainer
                        weblistId={selectedWeblist.id}
                        theme={theme}
                        maxWidth={
                          selectedWeblist?.states?.length === 1 ? 60 : 100
                        }
                        showIcons={
                          weblistIndex === selectedWeblist.states.length - 1
                        }
                        title={weblistItem}
                        state={weblistIndex + 1}
                        key={weblistItem + "_" + weblistIndex}
                      >
                        {weblistItems
                          .filter(
                            (x) =>
                              x.pinned >= 0 &&
                              x.state === weblistIndex + 1 &&
                              filterTags(x) &&
                              filterOnlyOrdersWithDetails(x) &&
                              filterOnlyOrdersWithUploads(x)
                          )
                          .map((weblistItem) => {
                            return (
                              <>
                                <ListItemMemo
                                  key={weblistItem.id}
                                  {...weblistItem}
                                ></ListItemMemo>
                              </>
                            );
                          })}
                        {showSpacer(weblistItems, weblistIndex + 1)}
                        {weblistItems
                          .filter(
                            (x) =>
                              x.pinned < 0 &&
                              x.state === weblistIndex + 1 &&
                              filterTags(x) &&
                              filterOnlyOrdersWithDetails(x) &&
                              filterOnlyOrdersWithUploads(x)
                          )
                          .map((weblistItem) => {
                            return (
                              <>
                                <ListItemMemo
                                  key={weblistItem.id}
                                  {...weblistItem}
                                ></ListItemMemo>
                              </>
                            );
                          })}
                      </ListContainer>
                    );
                  })}
                  {!isDesktop && (
                    <MobileFooter theme={theme}>
                      {selectedWeblist.states.map((item, index) => {
                        return (
                          <Button
                            onClick={() => {
                              let target = document.getElementById(
                                `state${index + 1}`
                              );
                              if (target) {
                                target.scrollIntoView({ behavior: "smooth" });
                              }
                            }}
                          >
                            {item}
                          </Button>
                        );
                      })}
                      <IconButton
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <ArrowCircleUpIcon />
                      </IconButton>
                    </MobileFooter>
                  )}
                </>
              )}
            </BoxWrapper>
          </div>
        </>
      )}
    </>
  );
}

export default Overview;
