import { createGlobalStyle, css } from "styled-components";
import { IOverviewStyles } from "../Interfaces/IOverviewStyles";

export const GlobalStyles = createGlobalStyle<IOverviewStyles>`
    
    @media print { 
        .app-navbar,.print-controls{
            display: none !important;
        }
    }

body{
   background-color: ${props => props.theme.palette.mode === 'dark' ? props.theme.palette.primary.dark : 'white'};
}

#gestureZone{
    ${props => !props.isDesktop && css`
        margin-top: 50px;
    `}
}

.MuiTabPanel-root{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.MuiDialog-paperFullWidth{
    margin:5px !important;
    width: calc(100% - 10px) !important;
}

.selected-item{
    box-shadow: rgb(99 99 99 / 85%) 2px 2px 6px 2px !important;
}

.MuiBox-root{
    ${props => props.theme.palette.mode === 'dark' && css`
    background-color: ${props => props.theme.palette.primary.dark} !important;
    `}
}

.MuiAccordion-root{
    ${props => props.theme.palette.mode === 'dark' && css`
        background-color: ${props.theme.palette.primary.dark} !important;
    `}
}

.light-text{
    ${props => props.theme.palette.mode === 'dark' && css`
        color:whitesmoke !important;
    `}
}

.MuiButton-text{
    ${props => props.theme.palette.mode === 'dark' && css`
        color:white !important;
    `} 
}

.MuiInputLabel-shrink{
    ${props => props.theme.palette.mode === 'dark' && css`
        color:white !important;
    `} 
   
}

`