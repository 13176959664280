import { Fade, FormControlLabel, Switch, TextField, Tooltip, useTheme } from "@mui/material"
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DeadlineSection } from "../StyledComponents/StyledComponents"
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import useMediaQuery from "../Hooks/useMediaQuery";
import { useState } from "react";

interface IDeadline {
    deadlineChanged: (deadline: string) => void;
}
const Deadline = ({ deadlineChanged }: IDeadline) => {

    const isDesktop = useMediaQuery('(min-width: 1400px)');
    const theme = useTheme();

    const [selectedDeadline, setSelectedDeadline] = useState(new Date());
    const [deadlineActive, setDeadlineActive] = useState(false);

    return (
        <DeadlineSection isDesktop={isDesktop}>

            <div style={{ color: theme.palette.mode === 'dark' ? '#fff' : '' }}>
                <FormControlLabel control={<Switch onChange={(event, value) => {
                    setDeadlineActive(value)
                }} />} label="Deadline" />

            </div>
            <Fade in={deadlineActive}>
                <div className="datepicker-section">
                    <LocalizationProvider adapterLocale={'da-DK'} dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            ampm={false}
                            inputFormat="DD-MM-YYYY HH:mm"
                            label="Deadline"
                            value={selectedDeadline}
                            onChange={(newValue: any) => {
                                if (newValue) {
                                    setSelectedDeadline(newValue)
                                    let value = newValue._d as Date
                                    let target = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}.${value.getHours()?.toString().padStart(2, "0")}.${value.getMinutes()?.toString().padStart(2, "0")}`
                                    deadlineChanged(target)
                                }

                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    {/* <Tooltip sx={{ margin: '15px 5px', color: theme.palette.mode === 'dark' ? 'whitesmoke' : '' }} placement="top-start" title="Der sendes en påmindelse via. mail omkring en dag før deadline udløber" arrow>
                        <InfoTwoToneIcon />
                    </Tooltip> */}
                </div>

            </Fade>
        </DeadlineSection>
    )
}

export default Deadline;
