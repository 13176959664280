import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const BoxWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
    padding: 20px;

    >div{
        display: flex;
        width: 40vw;
        justify-content: center;
        align-items: center;
        place-self: center;
        align-content: center;
        align-content: center;
        flex-direction: column;
        padding: 20px;

    }

`

const NotLoggedIn = () => {
    return (
        <BoxWrapper>
            <Card>
                <Typography fontWeight={'bold'} fontSize={'18px'}>Ikke logget ind</Typography>
                <div>
                    <Typography mt={'20px'}>Du skal være logget ind for at kunne bruge Weblist.dk</Typography>
                </div>
                <div>
                    <NavLink to={'/bruger'}>Gå til login</NavLink>
                </div>
            </Card>
        </BoxWrapper>
    )
}

export default NotLoggedIn;