function setStorage<T>(key: string, item: T): Promise<boolean> {

    return new Promise((resolve, reject) => {

        try {

            if (typeof item === "object") {
                localStorage.setItem(key, JSON.stringify(item))
            }
            else if (typeof item === "string" || typeof item === "number" || typeof item === "boolean") {
                localStorage.setItem(key, item.toString())
            }

            resolve(true)
        }
        catch (exception) {
            reject(exception)
        }

    });

}

function getStorage<T>(key: string): Promise<T | null> {

    return new Promise((resolve, reject) => {

        try {

            let target = localStorage.getItem(key)

            if (target === null || target === undefined) {
                resolve(null)
            }
            else {
                let parsed = JSON.parse(target) as T;
                resolve(parsed)
            }

        }
        catch (exception) {
            reject(null)
        }

    });

}

function deleteStorage<T>(key: string): Promise<null> {

    return new Promise((resolve, reject) => {

        try {

            let target = localStorage.getItem(key)

            if (target === null || target === undefined) {
                reject(`No item found with key ${key}`)
            }
            else {
                localStorage.removeItem(key)
            }

            resolve(null)

        }
        catch (exception) {
            reject(exception)
        }

    });

}

export { setStorage, getStorage, deleteStorage }