import {
  Dialog,
  Input,
  Divider,
  Typography,
  Button,
  DialogActions,
  IconButton,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import { Weblist } from "../Models/Weblist";
import WebListItem from "../Models/WebListItem";
import { formatDateTime, getTimeToDeadline } from "../Services/UtilsService";
import Upload from "./Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";
import UserContext from "../Contexts/UserContext";
import ApiService from "../Services/ApiService";
import { EditWeblistModel } from "../Models/Dto/EditWeblistModel";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ArchiveIcon from "@mui/icons-material/Archive";
import { validUrl } from "../Services/UtilsService";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TagsContext from "../Contexts/TagsContext";
import Deadline from "./Deadline";

interface EditDialogProps {
  weblistItems: Array<WebListItem>;
  selectedWeblist: Weblist;
  editCallback: (item: WebListItem) => void;
  changeStateCallback: (item: WebListItem | null, state: number) => void;
  handleSaveEditCallback: (
    index: number,
    item: WebListItem,
    unshift: boolean
  ) => void;
  editItem: WebListItem | null;
  setEditItem: React.Dispatch<React.SetStateAction<WebListItem | null>>;
  togglePinnedCallback: (itemId: number) => void;
  archiveCallback: (item: WebListItem) => void;
  deleteCallback: (item: WebListItem) => void;
  moveCallback: (item: WebListItem) => void;
  isNewNote: boolean;
  setIsNewNote: (value: React.SetStateAction<boolean>) => void;
  handleSubmit: (title: string, details: string, deadline: string) => void;
}

const EditDialog = ({
  setIsNewNote,
  handleSubmit,
  isNewNote,
  moveCallback,
  deleteCallback,
  archiveCallback,
  togglePinnedCallback,
  handleSaveEditCallback,
  editItem,
  setEditItem,
  weblistItems,
  selectedWeblist,
  editCallback,
  changeStateCallback,
}: EditDialogProps) => {
  const userContext = useContext(UserContext);

  const isDesktop = useMediaQuery("(min-width: 1400px)");

  const [editTitle, setEditTitle] = useState("");
  const [editDetails, setEditDetails] = useState("");
  const [selectedUpload, setSelectedUpload] = useState<FormData | null>(null);
  const [selectedUploadTitle, setSelectedUploadTitle] = useState<string | null>(
    null
  );

  const [activeTags, setActiveTags] = useState<string>("");

  const weblistTags = useContext(TagsContext);

  const titleRef = useRef(null);

  let selectedDeadline = "";

  useEffect(() => {
    setEditTitle("");
    setEditDetails("");

    setTimeout(() => {
      if (titleRef.current) {
        (titleRef.current as HTMLInputElement).click();
      }
    }, 100);

    if (editItem?.title) {
      setEditTitle(editItem?.title);
      setEditDetails(editItem.details);
    }

    setActiveTags("");

    if (editItem && editItem.tags) {
      setActiveTags(editItem.tags);
    }
  }, [editItem]);

  const handleSaveEdit = async (state?: number) => {
    if (editItem && userContext) {
      let index = weblistItems.findIndex((item) => item.id === editItem.id);

      let editModel = new EditWeblistModel();
      editModel.title = editTitle;
      editModel.details = editDetails;
      editModel.passwordHash = userContext.passwordHash;
      editModel.state = state || editItem.state;
      editModel.weblistId = editItem.id;
      editModel.tags = activeTags;
      editModel.deadline = selectedDeadline;

      let result = await ApiService.editWeblist(editModel);
      if (result.isSuccessful) {
        handleSaveEditCallback(index, result.data as WebListItem, true);
      }
    }
  };

  const saveAndExit = () => {
    setEditDetails("");
    setEditTitle("");
    setEditItem(null);

    if (isNewNote) {
      handleSubmit(editTitle, editDetails, selectedDeadline);
    } else {
      handleSaveEdit();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.code === "Enter") {
      saveAndExit();
    }
  };

  return (
    <>
      <Dialog
        onClose={() => {
          setEditItem(null);
        }}
        open={editItem != null}
        fullWidth={true}
      >
        <div style={{ padding: "15px" }}>
          <div>
            <Input
              onKeyDown={handleKeyDown}
              autoFocus={isNewNote}
              ref={titleRef}
              tabIndex={0}
              placeholder="Titel"
              fullWidth={true}
              value={editTitle}
              onChange={(e) => {
                setEditTitle(e.target.value);
              }}
            ></Input>
            <div style={{ marginTop: "20px", marginBottom: "40px" }}></div>
            <DefaultEditor
              tabIndex={1}
              style={{ minHeight: isDesktop ? "150px" : "50px" }}
              onClick={(e) => {
                let event = e as any;
                if (validUrl(event.target.innerText)) {
                  window.open(event.target.innerText, "_blank");
                }
              }}
              id="test"
              placeholder="Detaljer"
              value={editDetails}
              onChange={(e) => {
                setEditDetails(e.target.value);
              }}
            ></DefaultEditor>
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Autocomplete
            sx={{ width: "calc(100% - 30px)", marginLeft: "15px" }}
            multiple
            limitTags={3}
            id="multiple-limit-tags"
            value={activeTags.split(",").filter((item) => item.length > 0)}
            options={weblistTags.map((tag) => tag.title)}
            onChange={(e, values) => {
              setActiveTags(values.filter((x) => x.length > 0).join(","));
            }}
            renderInput={(params) => (
              <TextField
                tabIndex={2}
                {...params}
                label="Tilføj tags"
                placeholder="Tilføj tags"
              />
            )}
          />
        </div>
        {!isDesktop && (
          <>
            <Divider />
            <div style={{ margin: "10px 0 10px 0" }}>
              <Typography
                variant="subtitle1"
                component="h1"
                textAlign={"center"}
              >
                Vælg stadie
              </Typography>
              <Typography textAlign={"center"}>
                <span>
                  {selectedWeblist.states.map((innerItem, innerIndex) => {
                    return (
                      <Button
                        onClick={() => {
                          changeStateCallback(editItem, innerIndex + 1);
                          setEditItem(null);
                        }}
                        disabled={
                          editItem == null || editItem.state === innerIndex + 1
                        }
                        size="small"
                        key={innerItem + "_" + innerIndex}
                      >
                        {innerItem}
                      </Button>
                    );
                  })}
                </span>
              </Typography>
            </div>
            <Divider />
          </>
        )}
        <div style={{ marginLeft: "10px" }}>
          {editItem && !isNewNote && (
            <Upload
              item={editItem}
              deleteCallback={(deleteItem) => {
                let target = weblistItems.find((x) => x.id == deleteItem.id);

                if (target !== undefined) {
                  target.upload = "";

                  let image = document.getElementById(
                    "uploadedImage"
                  ) as HTMLImageElement;
                  if (image) {
                    image.src = "";
                  }

                  let file = document.getElementById("uploadedFileText");
                  if (file) {
                    file.innerText = "";
                  }

                  setSelectedUpload(null);
                  setSelectedUploadTitle(null);

                  setEditItem({ ...editItem, upload: "" });
                }
              }}
              uploadCallback={(uploadItem, fileName) => {
                setEditItem({ ...editItem, upload: fileName });
              }}
            />
          )}
        </div>
        <div style={{ display: "flex" }}>
          {editItem && editItem.deadline?.length > 0 ? (
            <Box ml={"15px"}>
              <Typography>
                deadline: {getTimeToDeadline(editItem.deadline)}
              </Typography>
              <Button
                variant="text"
                onClick={() => {
                  setEditItem({ ...editItem, deadline: "" });
                }}
              >
                slet deadline
              </Button>
            </Box>
          ) : (
            <Deadline
              deadlineChanged={(deadline) => {
                selectedDeadline = deadline;
              }}
            ></Deadline>
          )}
        </div>
        <DialogActions>
          {editItem?.dateChanged && !isDesktop && (
            <span>
              {editItem.deadline && (
                <div>
                  <Typography marginLeft={"10px"} fontSize={12}>
                    deadline {editItem.deadline}
                  </Typography>
                </div>
              )}
              <div>
                {isDesktop && (
                  <Typography marginLeft={"10px"} fontSize={12} mt={"10px"}>
                    redigeret {formatDateTime(editItem.dateChanged, true)}
                  </Typography>
                )}
              </div>
            </span>
          )}
          <span
            style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              onClick={async () => {
                saveAndExit();
                setIsNewNote(false);
              }}
            >
              Gem
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setEditItem(null);
                setIsNewNote(false);
              }}
            >
              Luk
            </Button>
          </span>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;
