class WebListItem {

    id: number = 0
    userId: string = ""
    dateCreated: Date = new Date()
    dateChanged: Date = new Date()
    title: string = ""
    details: string = ""
    state: number = 0
    pinned: number = -1;
    weblistId?: number;
    weblistName: string = "Mine noter";
    deadline: string = ""
    selected: boolean = false;
    upload: string = "";
    tags: string = "";
    sortOrder: number = 1;

}

export default WebListItem