export class Weblist {

    constructor(title: string, states: string[]) {
        this.title = title
        this.states = states;
        // this.states = ["Aktiv", "Planlagt", "Udført"]
    }
    id: number = 0;
    userId: number = 0;
    dateCreated: Date = new Date();
    title: string = ""
    color: string = ""
    states: string[] = []
    deleted: boolean = false
}