import styled from "styled-components"
import { Card, CardContent, Link, Typography, useTheme } from "@mui/material";

export const NotFound = () => {

    const theme = useTheme()

    const NotFoundWrapper = styled.div`
        display: flex;
        justify-content: center;
        margin: 5px;
        height: 90vh;
        
        >div{
            place-self: center;

            >div{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        
    `

    return (
        <NotFoundWrapper>
            <div>
                {/* <Card>
                    <CardContent> */}
                <div>
                    <Typography marginBottom={'20px'} fontSize={'34'} variant={'h4'} fontWeight={'bold'} color={theme.palette.mode === 'dark' ? 'white' : ''}>Side ikke fundet :-(</Typography>
                    <img width={'300px'} src={require('../Images/404.jpg')} style={{ borderRadius: '10px' }}></img>

                    <Typography color={theme.palette.mode === 'dark' ? 'white' : ''} marginTop={'20px'}>
                        Hvis du mener der er sket en fejl, kan du kontakte <Link href="mailto:kontakt@thomasrasmussen.dk">kontakt@thomasrasmussen.dk</Link>
                    </Typography>
                </div>
                {/* </CardContent>
                </Card> */}
            </div>
        </NotFoundWrapper>
    )
}