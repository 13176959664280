import axios from "axios"
import ApiResponseModel from "../Models/ApiResponseModel";
import { ArchiveModel } from "../Models/Dto/ArchiveModel";
import { ClearAllModel } from "../Models/Dto/ClearAllModel";
import { CreateUserModel } from "../Models/Dto/CreateUserModel";
import { DeleteWeblistCategoryModel } from "../Models/Dto/DeleteWeblistCategoryModel";
import { EditWeblistModel } from "../Models/Dto/EditWeblistModel";
import { GetWeblistModel } from "../Models/Dto/GetWeblistModel";
import { PostWeblistCategoryModel } from "../Models/Dto/PostWeblistCategoryModel";
import { PostWeblistModel } from "../Models/Dto/PostWeblistModel";
import { TogglePinnedModel } from "../Models/Dto/TogglePinnedModel";
import { Weblist } from "../Models/Weblist";
import WebListItem from "../Models/WebListItem";
import axiosRetry from 'axios-retry';
import { ChangePasswordModel } from "../Models/Dto/ChangePasswordModel";
import { MultiActionModel } from "../Models/Dto/MultiActionModel";
import { GetArchiveModel } from "../Models/Dto/GetArchiveModel";
import { MoveWeblistModel } from "../Models/Dto/MoveWeblistModel";
import { ShareWeblistModel } from "../Models/Dto/ShareWeblistModel";
import { DeleteWeblistShareModel } from "../Models/Dto/DeleteWeblistShareModel";
import { ArchiveWeblistModel } from "../Models/Dto/ArchiveWeblistModel";
import { GetWeblistTagsModel } from "../Models/Dto/GetWeblistTagsModel";
import { PostWeblistTagModel } from "../Models/Dto/PostWeblistTagModel";
import { DeleteWeblistTagModel } from "../Models/Dto/DeleteWeblistTagModel";
import { LoginOrCreateModel } from "../Models/Dto/LoginOrCreateModel";



const baseUrl = "https://www.api.thomasrasmussen.dk/api/";
const coreBaseUrl = "https://www.coreapi.thomasrasmussen.dk/api/";
const testUrl = "http://localhost:50492/api/";

axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 200;
    },
    retryCondition: (_error) => true
});

enum RetryRequest {
    WeblistCategories,
    WeblistItems,
    WeblistTags
}

const ApiService = {

    retryRequest(type: RetryRequest, request: any) {


        // if (type === RetryRequest.WeblistCategories && categoryRetry < 3) {
        //     request()
        // }
        // if (type === RetryRequest.WeblistItems && weblistItemsRetry < 3) {
        //     request()
        // }
        // if (type === RetryRequest.WeblistTags && weblistTagsRetry < 3) {
        //     request()
        // }
    },

    login(email: string, password: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = {
                    "Email": email,
                    "Password": password,
                    "Website": ""
                }

                let result = await axios.post(`${coreBaseUrl}Users/Login`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;


                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        });
    },
    loginOrCreateUser(name: string, email: string, googleToken: string): Promise<ApiResponseModel> {
        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new LoginOrCreateModel()
                model.name = name;
                model.email = email;
                model.googleToken = googleToken;
                model.website = "weblist.dk";

                let result = await axios.post(`${coreBaseUrl}Users/LoginOrCreateUser`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;


                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        });
    },
    createUser(name: string, email: string, password: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new CreateUserModel();
                model.email = email;
                model.name = name;
                model.password = password;
                model.passwordRepeat = password;


                let result = await axios.post(`${baseUrl}Users/CreateUser`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;


                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        });
    },

    getAllWeblists(model: GetWeblistModel): Promise<ApiResponseModel> {
        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let result = await axios.post(`${coreBaseUrl}Weblist/getWeblistItems`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data as Array<WebListItem>;


                    resolve(response);
                    console.log("response: ", response)
                }
                else {

                    this.retryRequest(RetryRequest.WeblistItems, this.getAllWeblists(model))

                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {

                this.retryRequest(RetryRequest.WeblistItems, this.getAllWeblists(model))

                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);


            }
        })
    },

    postWeblist(model: PostWeblistModel): Promise<ApiResponseModel> {
        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let result = await axios.post(`${coreBaseUrl}Weblist/post`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;


                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },
    editWeblist(model: EditWeblistModel): Promise<ApiResponseModel> {
        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let result = await axios.post(`${coreBaseUrl}Weblist/edit`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data as WebListItem;


                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },
    getWeblistCategories(userId: number, includeArchived: boolean = false): Promise<ApiResponseModel> {
        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let result = await axios.get(`${coreBaseUrl}Weblist/getweblists/${userId}/${includeArchived}`);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data as Array<Weblist>;


                    resolve(response);
                }
                else {

                    this.retryRequest(RetryRequest.WeblistCategories, this.getWeblistCategories(userId, includeArchived))
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {

                this.retryRequest(RetryRequest.WeblistCategories, this.getWeblistCategories(userId, includeArchived))

                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);


            }
        })
    },
    createWeblistCategory(userId: number, title: string, color: string, states: string[]): Promise<ApiResponseModel> {
        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new PostWeblistCategoryModel()
                model.title = title
                model.userId = userId
                model.color = color
                model.states = states.length === 0 ? ["Planlagt", "Aktiv", "Udført"] : states

                let result = await axios.post(`${coreBaseUrl}Weblist/postweblist`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data as Array<Weblist>;

                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },
    deleteWeblistCategory(passwordHash: string, weblistCategoryId: number): Promise<ApiResponseModel> {


        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new DeleteWeblistCategoryModel()
                model.passwordHash = passwordHash
                model.weblistId = weblistCategoryId


                let result = await axios.post(`${coreBaseUrl}Weblist/deleteweblist`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },
    clearAll(passwordHash: string, archive?: boolean): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new ClearAllModel()
                model.passwordHash = passwordHash
                model.archive = archive

                let result = await axios.post(`${coreBaseUrl}Weblist/clearAll`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data as Array<WebListItem>;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    archive(passwordHash: string, weblistItemId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new ArchiveModel()
                model.passwordHash = passwordHash
                model.weblistItemId = weblistItemId;

                let result = await axios.post(`${coreBaseUrl}Weblist/archive`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },

    togglePinned(passwordHash: string, weblistId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new TogglePinnedModel()
                model.passwordHash = passwordHash
                model.weblistId = weblistId

                let result = await axios.post(`${coreBaseUrl}Weblist/togglePinned`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },

    requestResetPasswordMail(email: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = {
                    "website": "weblist.dk",
                    "email": email
                }


                let result = await axios.post(`${coreBaseUrl}Users/RequestPasswordReset`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },

    changePassword(email: string, newPassword: string, newPasswordRepeat: string, validationCode: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new ChangePasswordModel()
                model.email = email
                model.newPassword = newPassword
                model.newPasswordRepeat = newPasswordRepeat
                model.validationCode = validationCode


                let result = await axios.post(`${coreBaseUrl}Users/ChangePassword`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },

    multiActions(passwordHash: string, ids: number[], action: string, weblistId?: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new MultiActionModel()
                model.passwordHash = passwordHash
                model.ids = ids
                model.action = action
                model.weblistId = weblistId

                let result = await axios.post(`${coreBaseUrl}Weblist/MultiActions`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    getArchive(passwordHash: string, yearsBack: number, maxCount: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new GetArchiveModel()
                model.passwordHash = passwordHash
                model.yearsBack = yearsBack
                model.maxCount = maxCount

                let result = await axios.post(`${coreBaseUrl}Weblist/GetArchive`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    moveWeblist(passwordHash: string, weblistId: number, weblistItemId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = new MoveWeblistModel()
                model.passwordHash = passwordHash
                model.weblistId = weblistId
                model.weblistItemId = weblistItemId

                console.log("Move model: ", model)
                let result = await axios.post(`${coreBaseUrl}Weblist/MoveList`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    upload(formFile: FormData, userId: number, weblistId: number, progress: (progressEvent: any) => void): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                const config = {
                    onUploadProgress: (progressEvent: any) => progress(progressEvent)
                }

                let result = await axios.post(`${coreBaseUrl}Weblist/Upload?userId=${userId}&weblistId=${weblistId}`, formFile, config);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    deleteUpload(userId: number, weblistId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let result = await axios.post(`${coreBaseUrl}Weblist/DeleteUpload?userId=${userId}&weblistId=${weblistId}`);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    getSharedLists(userId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let result = await axios.get(`${coreBaseUrl}weblistShare/GetShares/${userId}`);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    getSharedWithMeLists(userId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let result = await axios.get(`${coreBaseUrl}weblistShare/GetSharedWithMe/${userId}`);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    shareWeblist(userEmail: string, sharedWeblist: number[], passwordHash: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new ShareWeblistModel()
                model.userEmail = userEmail
                model.sharedWeblist = sharedWeblist
                model.passwordHash = passwordHash

                let result = await axios.post(`${coreBaseUrl}weblistShare/ShareWeblist`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    deleteWeblistShare(passwordHash: string, weblistId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new DeleteWeblistShareModel()
                model.passwordHash = passwordHash
                model.weblistId = weblistId

                let result = await axios.delete(`${coreBaseUrl}weblistShare/DeleteWeblistShare`, { data: model });

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    archiveCategory(passwordHash: string, weblistId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new ArchiveWeblistModel()
                model.passwordHash = passwordHash
                model.weblistId = weblistId

                let result = await axios.post(`${coreBaseUrl}weblist/ArchiveWeblist`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },

    getWeblistTags(passwordHash: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new GetWeblistTagsModel()
                model.passwordHash = passwordHash

                let result = await axios.post(`${coreBaseUrl}WeblistTag/GetWeblistTags`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    this.retryRequest(RetryRequest.WeblistTags, this.getWeblistTags(passwordHash))

                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {

                this.retryRequest(RetryRequest.WeblistTags, this.getWeblistTags(passwordHash))

                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);

            }
        })

    },
    postWeblistTag(passwordHash: string, title: string): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new PostWeblistTagModel()
                model.passwordHash = passwordHash
                model.title = title

                let result = await axios.post(`${coreBaseUrl}WeblistTag/PostWeblistTag`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })

    },
    deleteWeblistTag(passwordHash: string, weblistTagId: number): Promise<ApiResponseModel> {

        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new DeleteWeblistTagModel()
                model.passwordHash = passwordHash
                model.weblistTagId = weblistTagId


                let result = await axios.delete(`${coreBaseUrl}WeblistTag/DeleteWeblistTag`, { data: model });

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },
    deleteWeblistItem(passwordHash: string, weblistItemId: number): Promise<ApiResponseModel> {


        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {


                let model = new DeleteWeblistCategoryModel()
                model.passwordHash = passwordHash
                model.weblistId = weblistItemId


                let result = await axios.delete(`${coreBaseUrl}Weblist/DeleteWeblistItem`, { data: model });

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },
    changeSortOrder(passwordHash: string, targetWeblistId: number, sortUp: boolean): Promise<ApiResponseModel> {


        return new Promise(async (resolve, reject) => {

            let response = new ApiResponseModel();

            try {

                let model = {
                    'PasswordHash': passwordHash,
                    'WeblistId': targetWeblistId,
                    'SortUp': sortUp
                }

                let result = await axios.post(`${coreBaseUrl}Weblist/ChangeSortOrder`, model);

                if (result.status.toString().startsWith("2")) {
                    response.isSuccessful = true;
                    response.data = result.data;
                    resolve(response);
                }
                else {
                    response.isSuccessful = false;
                    response.error = result.statusText;
                    resolve(response);
                }

            } catch (error: any) {
                console.log(error);
                response.isSuccessful = false;
                response.error = error || "Ukendt fejl opstod";
                resolve(response);
            }
        })
    },




}


export default ApiService;